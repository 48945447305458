import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonModal,
  IonPage,
  IonPopover,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";
import { format, parse } from "date-fns";
import "./CoreChapterContents.css";
import "../../Fonts.css";
import "../../../src/theme/common.css";
import ProgressContainer from "../../components/progress/Progress";
import { DataService } from "../../services/DataService";
import { useEffect, useRef, useState } from "react";
import AccordianCoreContent from "../../components/accordian_core_content/AccordianCoreContent";
import CoreChapterActivityQuestion from "../../components/core_chapter_activity_question/CoreChapterActivityQuestion";
import CoreContentCompletedImgText from "../../components/core_content_completed_img_text/CoreContentCompletedImgText";
import Accordion from "../../components/accordion/Accordion";
import SubAccordion from "../../components/sub_accordian/SubAccordian";
import AccordianMainImgText from "../../components/accordian_mainimg_text/AccordianMainimgText";
import AccordianText from "../../components/accordian_text/AccordianText";
import MainImgText from "../../components/mainimg_text/MainImgText";
import LearnMoreImgText from "../../components/learn_more_img_text/LearnMoreImgText";
import FiveStarRating from "../../components/five_star_rating/FiveStarRating";
import MCQMultipleCheck from "../../components/mcq_multiple_check/MCQMultipleCheck";
import StarRatingSummary from "../../components/star_rating_summary/StarRatingSummary";
import SelectedPointsSummary from "../../components/selected_points_summary/SelectedPointsSummary";
import {
  DataSubmissionService,
  c2A1GoalType,
} from "../../services/DataSubmissionService";
import MaterialsMultipleSelect from "../../components/materials_multiple_select/MaterialsMultipleSelect";
import TaskSelection from "../../components/task_selection/TaskSelection";
import ImgTextColTappable from "../../components/img_text_col_tappable/ImgTextColTappable";
import BarrierMCQMultipleCheck from "../../components/barrier_mcq_multiple_check/BarrierMCQMultipleCheck";
import AccordianBarrierSolution from "../../components/accordian_barrier_solution/AccordianBarrierSolution";
import AccordianToolsSelected from "../../components/accordian_tools_selected/AccordianToolsSelected";
import AccordianBarrierSolutionSummary from "../../components/accordian_barrier_solution_summary/AccordianBarrierSolutionSummary";
import AccordianSummaryColorText from "../../components/accordian_summary_color_text/AccordianSummaryColorText";
import CompletedChaptersSummary from "../../components/completed_chapters_summary/CompletedChaptersSummary";
import AccordianGoalSummary from "../../components/accordian_goal_summary/AccordianGoalSummary";
import AccordianResourcesSummary from "../../components/accordian_resources_summary/AccordianResourcesSummary";
import CoreContentCompletedImgTextSummary from "../../components/core_content_completed_img_text_summary/CoreContentCompletedImgTextSummary";
import CoreContentCompletedImgTextSetGoal from "../../components/core_content_completed_img_text_setgoal/CoreContentCompletedImgTextSetGoal";
import {
  getApi,
  postC1A1,
  postC2A1,
  postQuizFnf,
  getLockUnLockPrize,
  postSelectedPrizeData,
  postNotificationSchedule2,
  postProfileData2
} from "../../services/dataApi";
import CoreFnf from "../../components/core_fnf/corefnf";
import CoreSingleCheckOption from "../../components/core_single_select_option/CoreSingleCheckOption";
import CoreIncrementDecrement from "../../components/core_increment_decrement/coreIncrementDecrement";
import CoreAccordionDescriptionIntro from "../../components/core_accordion_description_intro/CoreAccordionDescriptionIntro";
import CoreAccordianImageDesc from "../../components/core_accordion_image_desc/CoreAccordianImageDesc";
import CoreVideoPlayer from "../../components/core_video-player/coreVideoPlayer";
import CoreForm from "../../components/core_form/CoreForm";
import CoreMCQ from "../../components/core_mcq/CoreMCQ";
import SummaryDescription from "../../components/summary_description/SummaryDescription";
import AccordianBioDesc from "../../components/accordian_bio_desc/AccordianBioDesc";
import AccordianCoreContentImgExp from "../../components/accordian_core_content_img_exp/AccordianCoreContentImgExp";
import TaskSelectionOption from "../../components/task_selection_option/TaskSelectionOption";
import { options } from "ionicons/icons";
import CoreForm1 from "../../components/core_form1/CoreForm1";
import CoreForm2 from "../../components/core_form2/CoreForm2";
import CoreForm3 from "../../components/core_form3/CoreForm3";
import CoreForm4 from "../../components/core_form4/CoreForm4";
import CoreForm5 from "../../components/core_form5/CoreForm5";
import CoreForm6 from "../../components/core_form6/CoreForm6";
import CoreForm7 from "../../components/core_form7/CoreForm7";
import LikertScale from "../../components/likert_scale_component/LikertScale";
// import CoreFormInit from "../../components/core_form_init/CoreFormInit";
import TaskSelectionOptionCore2 from "../../components/task_selection_option_core2/TaskSelectionOptionCore2";
import { C2DataSubmissionService } from "../../services/C2DataSubmissionService";
import CoreForm8 from "../../components/core_form8/CoreForm8";
import CoreFnf2 from "../../components/core_fnf2/corefnf2";
import CoreFormNone from "../../components/core_form_none/CoreFormNone";
import AccordianActivityPerson from "../../components/accordian_activity_person/AccordianActivityPerson";
import ImageDescriptionPopOver from "../../components/image_description_popover/ImageDescriptionPopOver";
import { ProgressDataService } from "../../services/ProgressDataService";

import { postProgress } from "../../services/dataApi";
import LoadingScreen from "../../components/loading_screen/LoadingScreen";
import { showText } from "pdf-lib";
import { QuizService } from "../../services/QuizService";
import { UserService } from "../../services/UserService";
import { SkinSelfCheckDataService } from "../../services/SkinSelfCheckDataService";
import DateTimePicker from "../../components/date_time_picker/dateTimePicker";

// interface CoreChapterContentsProps {
//   jsonContents: any;
// }
var currentScreenIndex = 0;
var currentScreenIndexUpdateC2A1Goal = -1;

var actualCurrentScreenContents: any[] = [];
var screensSkipped = 0;
var skippedScreenIndex = -1;
let accordianSelectedIndex = -1;

let showBackButton = true;


const CoreChapterContents: React.FC = () => {
  var router = useIonRouter();
  const modal = useRef<HTMLIonModalElement>(null);


  const reminderText = "Remind me to finish my <strong>Skin Self Check</strong>:";


  //Reminders for first SSE
  const [openReminder, setOpenReminder] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [disableReminderBtn, setReminderBtnDisable] = useState(false);
  const [reminderDateTime, setReminderDateTime] = useState("");

  //C2A1 Popover
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });

  //Claim Rewards Popover
  const [showRewardsPopover, setShowRewardsPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });

  var [refreshContents, setRefreshContents] = useState(false);
  var [lessonEndOpen, setLessonEndOpen] = useState(true);
  var [enableNextBtn, setEnableNextBtn] = useState(true);
  const [clickedIndices, setClickedIndices] = useState<number[]>([]);

  const [loaderText, setLoaderText] = useState("Loading");


  var isLesson = false;
  // let localvalue=localStorage.getItem("currentcorescreens");
  // if(localvalue!==null){
  //   DataService.currentCoreScreens=JSON.parse(localvalue);
  // }
  var currentCoreScreens =DataService.currentCoreScreens;
  var [currentCoreScreen, setCurrentCoreScreen] = useState(
    DataService.currentCoreScreens[0]
  );

  console.log(DataService.coresDataJSON);
  const [coursedata, setcourseData] = useState({ cores: [] });

  const [showLoader, setShowLoader] = useState(false);

  const openLoader = () => {
    setShowLoader(true);
  };

  const closeLoader = () => {
    setShowLoader(false);
  };

  const getCurrentScreenIndex = () => {
    let retVal = 0;

    if (DataService.isEntryToC1A1ThroughMyStuff) {
      DataService.isEntryToC1A1ThroughMyStuff = false;
      currentScreenIndex = 3;
      retVal = 3;
    }
    return retVal;
  };

  useEffect(() => {
    currentScreenIndex = 0;

    if (DataService.coreOpenedFromCoresTab) {
      showBackButton = true;
    } else {
      showBackButton = false;
    }

    if (DataService.isEntryToC1A1ThroughMyStuff) {
      DataService.isEntryToC1A1ThroughMyStuff = false;
      currentScreenIndex = 2;

      setTimeout(() => {
        resetHiddenContents();
        setCurrentCoreScreen(currentCoreScreens[2]);
      }, 0);
    }

    if (DataService.isEntryToC2A1ThroughMyStuff) {
      DataService.isEntryToC2A1ThroughMyStuff = false;

      let c2A1Goal = DataService.selectedC2A1Goal;
      let goalTitle = c2A1Goal.title;

      if (goalTitle === "Use sunscreen more often") {
        setTimeout(() => {
          resetHiddenContents();
          currentScreenIndexUpdateC2A1Goal = 2;
          setCurrentCoreScreen(currentCoreScreens[2]);
        }, 0);
      } else if (goalTitle === "Wear protective clothing more often") {
        setTimeout(() => {
          resetHiddenContents();
          currentScreenIndexUpdateC2A1Goal = 3;
          setCurrentCoreScreen(currentCoreScreens[3]);
        }, 0);
      } else if (goalTitle === "Wear a wide-brimmed hat more often") {
        setTimeout(() => {
          resetHiddenContents();
          currentScreenIndexUpdateC2A1Goal = 4;
          setCurrentCoreScreen(currentCoreScreens[4]);
        }, 0);
      } else if (goalTitle === "Wear sunglasses more often") {
        setTimeout(() => {
          resetHiddenContents();
          currentScreenIndexUpdateC2A1Goal = 5;
          setCurrentCoreScreen(currentCoreScreens[5]);
        }, 0);
      } else if (goalTitle === "Limit my sun exposure") {
        setTimeout(() => {
          resetHiddenContents();
          currentScreenIndexUpdateC2A1Goal = 6;
          setCurrentCoreScreen(currentCoreScreens[6]);
        }, 0);
      }

    }

    actualCurrentScreenContents = [];
    screensSkipped = 0;
    skippedScreenIndex = -1;

    console.log(currentCoreScreen);

    checkContinueBtnEnable();

    const fetchData = async () => {
      try {
        console.log(DataService.coresDataJSON);
        const responseData = await getApi("cores-rest");
        console.log(responseData);
        setcourseData(responseData);
        DataService.coresDataJSON = responseData;
        console.log("here in json", DataService.coresDataJSON);
        checkLockUnLockPrize()
        return;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (DataService.getCoresFromBE === true) {
      fetchData();
    }
    // fetchData();
    console.log("currentScreenIndex", currentCoreScreen);
  }, []);

  const resetReminderScreens = () => {
    SkinSelfCheckDataService.remScreen1Open = false;
    SkinSelfCheckDataService.remScreen2Open = true;
    SkinSelfCheckDataService.remScreen3Open = false;

    SkinSelfCheckDataService.remScreen1OpenTemp = false;
    SkinSelfCheckDataService.remScreen2OpenTemp = true;
    SkinSelfCheckDataService.remScreen3OpenTemp = false;
  };

  const dateTimePickerHandler = (value: string) => {
    SkinSelfCheckDataService.skincheckReminderDateTime="";
    let selTime = new Date(value);
    let currentTime = new Date().getTime();
    //if (selTime.getTime() >= currentTime) {
      setReminderBtnDisable(false);
      setReminderDateTime(format(selTime, "MMM dd yyyy hh:mm a"));
      SkinSelfCheckDataService.skincheckReminderDateTime = value;

      SkinSelfCheckDataService.remScreen1OpenTemp = false;
      SkinSelfCheckDataService.remScreen2OpenTemp = false;
      SkinSelfCheckDataService.remScreen3OpenTemp = true;
    // } else {
    //   setShowAlert(true);
    //   setReminderBtnDisable(true);
    //   console.log("current time less than", selTime);
    // }

  };

  const setReminderHandler = () => {
    SkinSelfCheckDataService.remScreen1Open =
      SkinSelfCheckDataService.remScreen1OpenTemp;
    SkinSelfCheckDataService.remScreen2Open =
      SkinSelfCheckDataService.remScreen2OpenTemp;
    SkinSelfCheckDataService.remScreen3Open =
      SkinSelfCheckDataService.remScreen3OpenTemp;

    setRefresh(!refresh);
  };
  const handleTimeAlert = () => {
    setShowAlert(false);
    setReminderBtnDisable(true);
  };
  const cancelReminderHandler = () => {


    // if (SkinSelfCheckDataService.remScreen2Open) {
    //   resetReminderScreens();
    //   setRefresh(!refresh);
    // } else if (SkinSelfCheckDataService.remScreen3Open) {
    //   resetReminderScreens();
    // } else {

    // }
    setOpenReminder(false);
    resetReminderScreens();
    // DataService.currentCoreIndex += 1;
    // DataService.currentChapterIndex = 0;
    // let core2Ch1Screens = DataService.getScreens(
    //   DataService.currentCoreIndex,
    //   DataService.currentChapterIndex
    // );
    // console.log(core2Ch1Screens);
    // DataService.currentCoreScreens = core2Ch1Screens;
    // if (DataService.currentCoreScreens) {
    //   resetHiddenContents();
    //   setCurrentCoreScreen(DataService.currentCoreScreens[0]);
    //   let currScreen = currentCoreScreens[currentScreenIndex];
    //   if (currScreen.footerButtons.length > 0) {
    //     setEnableNextBtn(!currScreen.footerButtons[0].isDisabled);
    //     console.log(enableNextBtn);
    //   }
    // }

  };

  async function checkLockUnLockPrize() {
    try {
      const responseData = await getLockUnLockPrize(
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            //Save user data
            console.log("resp before", respData);
            var resp: any[] = respData.message;
            console.log("resp here", resp);
            var prizes = [
              {
                id: "core1-completion",
                text: "Section 1",
                imgUrl: "assets/images/Learn.svg",
                showGetPrize: false,
                progress: null,
                progressTotal: null,
                showRedeemedPrizeDetails: false

              },
              {
                id: "core2-completion",
                text: "Section 2",
                imgUrl: "assets/images/SunSafe2.svg",
                showGetPrize: false,
                progress: null,
                progressTotal: null,
                showRedeemedPrizeDetails: false
              }
            ];
            for (var i = 0; i < prizes.length; i++) {
              var localprize = prizes[i];
              for (var j = 0; j < resp.length; j++) {
                var localresp = resp[j];
                if (localresp.title.indexOf(localprize.text) != -1) {
                  if (localresp.title.indexOf("Completed") != -1) {
                    console.log("here in reedem...", localresp.isRedeem);
                    if (localresp.title.indexOf("Section 1") != -1) {
                      if (localresp.isRedeem == true) {
                        localprize.showGetPrize = false;
                        SkinSelfCheckDataService.showSection1RewardFlag = false;
                      } else {
                        localprize.showGetPrize = true;
                        SkinSelfCheckDataService.showSection1RewardFlag = true;

                      }
                    } else {
                      if (localresp.isRedeem == true) {
                        localprize.showGetPrize = false;
                        SkinSelfCheckDataService.showSSERewardFlag = false;
                      } else {
                        localprize.showGetPrize = true;
                        SkinSelfCheckDataService.showSSERewardFlag = true;

                      }
                    }

                    console.log("here in core", SkinSelfCheckDataService.showSSERewardFlag)
                    break;
                  }
                }
              }
            }
          } else if (!isSuccess) {
            //Error
            console.log(" GET prize failure--" + respData);
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Error posting prize:", error);
    }
  }



  async function postSSENotificationReminder(obj: number) {
    try {
      openLoader();
      setLoaderText("Setting Skin Self Check Notification Reminder")
      const responseData = await postProfileData2(
        obj,
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            alert("Skin Self Check Notification Reminder Set Successfully");
           
            closeLoader();
            setReminderHandler();
          } else if (!isSuccess) {
            //Error
            SkinSelfCheckDataService.showFirstSSERemainderFlag = true;
            alert("Something went wrong.Please try again later!!!");
            closeLoader();
            console.log(respData);
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      alert("Something went wrong.Please try again later!!!");
      closeLoader();
      console.error(" ", error);
    }
  }
  async function postActivityData() {
    let isCore1 = DataService.currentCoreIndex === 0 ? true : false;
    if (isCore1) {
      let obj = DataSubmissionService.getC1A1BEJson();
      try {
        setLoaderText("Saving Chapter Progress")
        openLoader();
        const responseData = await postC1A1(
          obj,
          (isSuccess: boolean, respData: any) => {
            if (isSuccess) {
              //Success
              closeLoader();
            } else if (!isSuccess) {
              //Error
              closeLoader();
              console.log(respData);
            }
          }
        );
        console.log(responseData);
      } catch (error) {
        closeLoader();
        console.error("Error fetching data:", error);
      }
    } else {
      let obj = DataSubmissionService.c2A1SkinSelfCheckGoals;
      console.log(obj);

      try {
        setLoaderText("Saving Chapter Progress")
        openLoader();
        const responseData = await postC2A1(
          obj,
          (isSuccess: boolean, respData: any) => {
            if (isSuccess) {
              //Success
              closeLoader();
            } else if (!isSuccess) {
              //Error
              closeLoader();
              console.log(respData);
            }
          }
        );
        console.log(responseData);
      } catch (error) {
        closeLoader();
        console.error("Error fetching data:", error);
      }
    }
  }

  async function postProgressData(obj: any, isActivityCh: boolean = false) {
    try {
      // openLoader();
      setLoaderText("Saving Chapter Progress")
      const responseData = await postProgress(
        obj,
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();

            // postActivityData
            if (isActivityCh) {
              postActivityData();
            }
          } else if (!isSuccess) {
            //Error
            closeLoader();
            navigateBackToCores();
            console.log(respData);
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      closeLoader();
      navigateBackToCores();
      console.error("Error fetching data:", error);
    }
  }

  const openIndividualAccordian = (content: any, i: number) => {
    let contnts: any[] = currentCoreScreen.contents;
    let filteredAccs = contnts.filter(
      (cont: any) => cont.type === content.type
    );
    filteredAccs.map((acc: any) => {
      if (acc.title === content.title) {
        accordianSelectedIndex = i;
        setRefreshContents(!refreshContents);
      }
    });
  };

  const updateProgress = () => {
    ProgressDataService.updateProgressOfCurrChapter(100);
    postProgressData(ProgressDataService.progressObj);
  };

  const getImgTextAccordianDynamicPoints = () => {
    let tempArr: Array<string> = [];
    if (
      DataService.currentCoreIndex === 1 &&
      DataService.currentChapterIndex === 3 &&
      currentScreenIndex === 5
    ) {
      if (C2DataSubmissionService.c2Ch4S3SunSafePriorityOpts.length > 0) {
        tempArr = C2DataSubmissionService.c2Ch4S3SunSafePriorityOpts;

        if (
          tempArr[tempArr.length - 1].toLowerCase().includes("enter my own") ||
          tempArr[tempArr.length - 1]
            .toLowerCase()
            .includes("think of any reasons")
        ) {
          const filArr = tempArr.filter(
            (option, i) =>
              !option.toLowerCase().includes("enter my own") &&
              !option.toLowerCase().includes("think of any reasons")
          );
          tempArr = filArr;
          console.log(filArr);
        }
      }
      if (C2DataSubmissionService.c2Ch4S3SunSafePriorityStrgs.length > 0) {
        tempArr = [
          ...tempArr,
          ...C2DataSubmissionService.c2Ch4S3SunSafePriorityStrgs,
        ];
      }
    }

    return tempArr.map((item: string, i: number) => (
      <AccordianText
        key={Math.random()}
        imageUrl={"/assets/images/Learning.svg"}
        title={item}
        description={C2DataSubmissionService.getC2Ch4BoostFeedbacks(item)}
        open={i === accordianSelectedIndex}
        isOpened={() => {
          accordianSelectedIndex = i;
          setRefreshContents(!refreshContents);
        }}
      />
      // <IonText>{item}</IonText>
    ));

    // tempArr.map((opt: any) => {
    //   return (
    //     <AccordianCoreContent
    //       key={Math.random()}
    //       title={opt}
    //       description=""
    //       imageUrl={""}
    //       expansionPoints={[""]}
    //       bioExpansionPoints={[""]}
    //       audioUrl=""
    //     />
    //   );
    // });
  };

  const c2A1getSelectedGoals = () => {
    let savedGoals = DataSubmissionService.c2A1SkinSelfCheckGoals.goals;
    let tempGoalTitles: string[] = [];

    savedGoals.forEach((goal: any) => {
      if (goal.mcqs[0].question !== "") {
        tempGoalTitles.push(goal.title);
      }
    });

    return tempGoalTitles;
  };

  const c2A1NavToBarriers = () => {
    if (
      DataSubmissionService.c2A1LatestGoalTitle === "Use sunscreen more often"
    ) {
      currentScreenIndex = 2;
    } else if (
      DataSubmissionService.c2A1LatestGoalTitle ===
      "Wear protective clothing more often"
    ) {
      currentScreenIndex = 3;
    } else if (
      DataSubmissionService.c2A1LatestGoalTitle ===
      "Wear a wide-brimmed hat more often"
    ) {
      currentScreenIndex = 4;
    } else if (
      DataSubmissionService.c2A1LatestGoalTitle === "Wear sunglasses more often"
    ) {
      currentScreenIndex = 5;
    } else if (
      DataSubmissionService.c2A1LatestGoalTitle === "Limit my sun exposure"
    ) {
      currentScreenIndex = 6;
    }

    setCurrentCoreScreen(currentCoreScreens[currentScreenIndex]);
    let currScreen = currentCoreScreens[currentScreenIndex];
    if (currScreen.footerButtons.length > 0) {
      setEnableNextBtn(!currScreen.footerButtons[0].isDisabled);
      console.log(enableNextBtn);
    }
  };

  const c2A1NavToGoals = () => {
    // let savedGoals = DataSubmissionService.c2A1SkinSelfCheckGoals.goals;
    // let tempGoalTitles: string[] = [];

    // savedGoals.forEach((goal: any) => {
    //   if (goal.mcqs[0].question !== "") {
    //     tempGoalTitles.push(goal.title);
    //   }
    // });

    // if (tempGoalTitles.length > 1) {
    //   return;
    // }

    currentScreenIndex = 1;
    setCurrentCoreScreen(currentCoreScreens[currentScreenIndex]);
    let currScreen = currentCoreScreens[currentScreenIndex];
    if ("footerButtons" in currScreen && currScreen.footerButtons.length > 0) {
      setEnableNextBtn(!currScreen.footerButtons[0].isDisabled);
      console.log(enableNextBtn);
    }
  };

  const resetCore2Ch3Content = () => {
    C2DataSubmissionService.c2Ch3S1IncDec = 0;
    C2DataSubmissionService.c2Ch3S1SinMCQ1 = "";
    C2DataSubmissionService.c2Ch3S1SinMCQ2 = "";
  };

  const resetCore2Ch3TanContent = () => {
    C2DataSubmissionService.c2Ch3S1IncTanDec = 0;
    C2DataSubmissionService.c2Ch3S1SinTanMCQ1 = "";
    C2DataSubmissionService.c2Ch3S1SinTanMCQ2 = "";
  };

  const getImgTextAccordiansPoints = (cont: any, i: number) => {
    let core = DataService.currentCoreIndex;
    let ch = DataService.currentChapterIndex;
    let scr = currentScreenIndex;

    if (core === 1 && ch === 4 && scr === 7) {
      let selGoal = DataSubmissionService.c2A1LatestGoalTitle;
      let goals = DataSubmissionService.c2A1SkinSelfCheckGoals;
      let filteredGoalsByTitle =
        DataSubmissionService.c2A1SkinSelfCheckGoals.goals.filter(
          (goal) => goal.title === DataSubmissionService.c2A1LatestGoalTitle
        );
      let gl = filteredGoalsByTitle;
      let mcqAnswers = filteredGoalsByTitle[0].mcqs[0].answers;

      let hasAnswer = false;
      mcqAnswers.forEach((answer: string) => {
        let contDesc = cont.description;
        console.log("Contttt Desccc ----> " + contDesc);
        let filteredAnswer =
          DataSubmissionService.c2A1LatestGoalTitle + "-" + answer;
        if (filteredAnswer === cont.description) {
          hasAnswer = true;
        }
      });

      if (hasAnswer) {
        return (
          <AccordianCoreContent
            key={Math.random()}
            title={cont.title}
            description=""
            imageUrl={cont.imageUrl}
            expansionPoints={cont.points}
            bioExpansionPoints={[""]}
            audioUrl=""
            open={i === accordianSelectedIndex}
            isOpened={() => {
              accordianSelectedIndex = i;
              setRefreshContents(!refreshContents);
            }}
          />
        );
      } else {
        return <></>;
      }
    } else {
      return (
        <AccordianCoreContent
          key={Math.random()}
          title={cont.title}
          description=""
          imageUrl={cont.imageUrl}
          expansionPoints={cont.points}
          bioExpansionPoints={[""]}
          audioUrl=""
          open={i === accordianSelectedIndex}
          isOpened={() => {
            accordianSelectedIndex = i;
            setRefreshContents(!refreshContents);
          }}
        />
      );
    }
  };

  const getImgTextAccordiansRender = (show: Boolean, cont: any) => {
    if (show) {
      return (
        <AccordianCoreContent
          key={Math.random()}
          title={cont.title}
          description=""
          imageUrl={cont.imageUrl}
          expansionPoints={cont.points}
          bioExpansionPoints={[""]}
          audioUrl=""
          open={false}
          isOpened={() => { }}
        />
      );
    } else {
      return <IonText>None</IonText>;
    }
  };

  const getSideSubHeadingText = (text: string) => {
    let retVal: string = text;

    if (text !== null && text !== undefined) {
      if (
        text.includes(
          "You rated the importance of engaging in sun-safe behaviors"
        ) ||
        text.includes("Your <strong>importance rating</strong>")
      ) {
        retVal = text.replace(
          "{x}",
          C2DataSubmissionService.c2Ch4SunSafeImpRating.toString()
        );
        return retVal;
      }

      if (
        text.includes(
          "On a scale from <strong>1</strong> (not at all important) to <strong>5</strong> (extremely important), you rated the importance of engaging in sun-safe behaviors as <strong>{x}.</strong>"
        )
      ) {
        retVal = text.replace(
          "{x}",
          C2DataSubmissionService.c2Ch4SunSafeImpRating.toString()
        );
        return retVal;
      }

      //Core 2 Ch1 Likert scale bottom
      if (text.includes("Your Sun-Safe Score")) {
        retVal = text.replace(
          "{x}",
          C2DataSubmissionService.c2Ch1TotalLikertScaleInd >= 0
            ? C2DataSubmissionService.c2Ch1TotalLikertScaleInd.toString()
            : "0"
        );
        retVal = retVal.replace(
          "{y}",
          C2DataSubmissionService.c2Ch1TotalLikertScaleInd <= 24
            ? C2DataSubmissionService.c2Ch1TotalLikertScaleIndShowsText
            : C2DataSubmissionService.c2Ch1TotalLikertScaleIndShowsTextGreat
        );
        return retVal;
      }

      if (text.includes("Due to your history of melanoma, {x}")) {
        retVal = retVal.replace(
          "{x}",
          C2DataSubmissionService.c2Ch1TotalLikertScaleInd <= 24
            ? C2DataSubmissionService.c2Ch1TotalLikertScaleIndShowsDesc1Text
            : C2DataSubmissionService.c2Ch1TotalLikertScaleIndShowsDesc2Text
        );
        return retVal;
      }
      if (text.includes("This Section will")) {
        retVal = retVal.replace(
          "{y}",
          C2DataSubmissionService.c2Ch1TotalLikertScaleInd <= 24
            ? C2DataSubmissionService.c2Ch1TotalLikertScaleIndShowsSol1Text
            : C2DataSubmissionService.c2Ch1TotalLikertScaleIndShowsSol2Text
        );
        return retVal;
      }
      ///////////////////////////////////

      if (text.includes("Your Sun-Safe Score of <strong>XX</strong>")) {
        retVal = text.replace(
          "{x}",
          C2DataSubmissionService.c2Ch1TotalLikertScaleInd.toString()
        );
      }

      //CH3 Sunbathing Behaviors//////////////
      if (text.includes("You indicated that you sunbathed")) {
        if (C2DataSubmissionService.c2Ch3S1SinMCQ1 === "No") {
          retVal =
            "It's great that you <strong>have never sunbathed before</strong> keep up the good work!";
          return retVal;
        }

        retVal = text.replace(
          "{x}",
          C2DataSubmissionService.c2Ch3S1IncDec.toString()
        );

        if (C2DataSubmissionService.c2Ch3S1SinMCQ2 === "Yes") {
          retVal = retVal.replace(
            " and <strong>don't plan to continue sunbathing in the next year.</strong>",
            " and <strong>plan to continue sunbathing in the next year.</strong>"
          );
          return retVal;
        } else if (C2DataSubmissionService.c2Ch3S1SinMCQ2 === "No") {
          retVal = retVal.replace(
            " and <strong>don't plan to continue sunbathing in the next year.</strong>",
            ". But it is good that you <strong>don’t plan to sunbathe in the next year.</strong>"
          );
          return retVal;
        } else if (C2DataSubmissionService.c2Ch3S1SinMCQ2 === "Not Sure") {
          retVal = retVal.replace(
            " and <strong>don't plan to continue sunbathing in the next year.</strong>",
            " and <strong>aren’t sure if you plan to sunbathe in the next year.</strong>”"
          );
          return retVal;
        }
      }

      if (
        C2DataSubmissionService.c2Ch3S1SinMCQ1 === "No" &&
        text.includes(
          "Due to your history of melanoma, <strong>it's especially important that you avoid sunbathing.</strong> Even occasional sunbathing can damage your skin and increase your risk of having another skin cancer. mySmartSkin will help you to come up with some sun-safe strategies to avoid sunbathing."
        )
      ) {
        retVal =
          "Remember that spending time in the sun, even if you're not actively trying to get a tan, can still damage your skin and increase your risk of having another skin cancer.";
        return retVal;
      }

      if (
        C2DataSubmissionService.c2Ch3S1SinMCQ1 === "Yes" &&
        text.includes(
          "Due to your history of melanoma, <strong>it's especially important that you avoid sunbathing.</strong> Even occasional sunbathing can damage your skin and increase your risk of having another skin cancer. mySmartSkin will help you to come up with some sun-safe strategies to avoid sunbathing."
        )
      ) {
        return text;
      }
      ///////////////////////////////////////////////////////////////////////////////

      //CH3 Tanning Behaviors//////////////
      if (
        text.includes(
          "You indicated that you <strong>weren't sure or definitely plan to sunbathe or indoor tan in the next year</strong>."
        )
      ) {
        if (C2DataSubmissionService.c2Ch3S1SinTanMCQ1 === "No") {
          retVal =
            "It's great that you <strong>have never used a tanning bed before</strong>";
          return retVal;
        }

        if (C2DataSubmissionService.c2Ch3S1SinTanMCQ1 === "Yes") {
          retVal =
            "You indicated that you tanned indoors " +
            C2DataSubmissionService.c2Ch3S1IncTanDec.toString() +
            " " +
            "times in the past year";
        }
        // retVal = text.replace(
        //   "{x}",
        //   C2DataSubmissionService.c2Ch3S1IncTanDec.toString()
        // );

        if (C2DataSubmissionService.c2Ch3S1SinTanMCQ2 === "Yes") {
          retVal =
            retVal +
            "…and <strong>plan to continue indoor tanning in the next year.</strong>";
          return retVal;
        } else if (C2DataSubmissionService.c2Ch3S1SinTanMCQ2 === "No") {
          retVal =
            retVal +
            ". It is great that you <strong>don’t plan to indoor tan in the next year.</strong>";
          return retVal;
        } else if (C2DataSubmissionService.c2Ch3S1SinTanMCQ2 === "Not Sure") {
          retVal =
            retVal +
            "…and <strong>aren’t sure if you plan to continue indoor tanning in the next year.</strong>";
          return retVal;
        }
      }

      if (
        C2DataSubmissionService.c2Ch3S1SinTanMCQ1 === "No" &&
        text.includes("Your tanning behaviors s2")
      ) {
        retVal =
          "Consider reminding your family and friends that indoor tanning damages the skin and greatly increases the risk of skin cancer.";
        return retVal;
      }

      if (
        C2DataSubmissionService.c2Ch3S1SinTanMCQ1 === "Yes" &&
        text.includes("Your tanning behaviors s2")
      ) {
        retVal =
          "Due to your history of melanoma, <strong>It's especially important that you avoid indoor tanning.</strong> Even occasional indoor tanning can damage your skin and increase your risk of having another skin cancer.";
        return retVal;
      }
      ///////////////////////////////////////////////////////////////////////////////

      if (
        text.includes(
          "mySmartSkin has feedback for you based on your behaviors of <strong>{x}</strong> using sunscreen with SPF 30 or higher on warm, sunny days."
        )
      ) {
        retVal = text.replace(
          "{x}",
          C2DataSubmissionService.getLikertDescriptionFromIndex(
            C2DataSubmissionService.c2Ch1S3LikertQstn1
          ).toLowerCase()
        );
        return retVal;
      }

      if (
        text.includes(
          "mySmartSkin has feedback for you based on your behaviors of <strong>{x}</strong> wearing a long-sleeved shirt, <strong>{y}</strong> wearing a wide-brimmed hat, and <strong>{z}</strong> wearing sunglasses on warm, sunny days."
        )
      ) {
        retVal = text.replace(
          "{x}",
          C2DataSubmissionService.getLikertDescriptionFromIndex(
            C2DataSubmissionService.c2Ch1S3LikertQstn2
          ).toLowerCase()
        );

        if (text.includes("wearing a long-sleeved shirt,")) {
          retVal = retVal.replace(
            "{y}",
            C2DataSubmissionService.getLikertDescriptionFromIndex(
              C2DataSubmissionService.c2Ch1S3LikertQstn3
            ).toLowerCase()
          );
        }
        if (text.includes("wearing a wide-brimmed hat, and")) {
          retVal = retVal.replace(
            "{z}",
            C2DataSubmissionService.getLikertDescriptionFromIndex(
              C2DataSubmissionService.c2Ch1S3LikertQstn4
            ).toLowerCase()
          );
        }
        return retVal;
      }

      // if (
      //   text.includes(
      //     "wearing a long-sleeved shirt,"
      //   )
      // ) {
      //   retVal = retVal.replace(
      //     "{y}",
      //     C2DataSubmissionService.getLikertDescriptionFromIndex(
      //       C2DataSubmissionService.c2Ch1S3LikertQstn2
      //     ).toLowerCase()
      //   );
      // }
      // if (
      //   text.includes(
      //     "wearing a wide-brimmed hat, and"
      //   )
      // ) {
      //   retVal = retVal.replace(
      //     "{z}",
      //     C2DataSubmissionService.getLikertDescriptionFromIndex(
      //       C2DataSubmissionService.c2Ch1S3LikertQstn3
      //     ).toLowerCase()
      //   );
      // }

      var desc = C2DataSubmissionService.getLikertDescriptionFromIndex(
        C2DataSubmissionService.c2Ch1S3LikertQstn1
      ).toLowerCase();

      if (
        text.includes(
          "sunscreen with an SPF of 30 or higher." // Unique string to be replaced
        ) &&
        (desc === "rarely" || desc === "sometimes" || desc === "never")
      ) {
        retVal = text.replace("{x}", C2DataSubmissionService.c2Ch3DynamicText1);
        return retVal;
      }

      if (
        text.includes(
          "sunscreen with an SPF of 30 or higher." // Unique string to be replaced
        ) &&
        desc === "often"
      ) {
        retVal = text.replace("{x}", C2DataSubmissionService.c2Ch3DynamicText2);
        return retVal;
      }

      if (
        text.includes(
          "sunscreen with an SPF of 30 or higher." // Unique string to be replaced
        ) &&
        desc === "always"
      ) {
        retVal = text.replace("{x}", C2DataSubmissionService.c2Ch3DynamicText3);
        return retVal;
      }

      var desc2 = C2DataSubmissionService.getLikertDescriptionFromIndex(
        C2DataSubmissionService.c2Ch1S3LikertQstn2
      ).toLowerCase();

      var desc3 = C2DataSubmissionService.getLikertDescriptionFromIndex(
        C2DataSubmissionService.c2Ch1S3LikertQstn3
      ).toLowerCase();

      var desc4 = C2DataSubmissionService.getLikertDescriptionFromIndex(
        C2DataSubmissionService.c2Ch1S3LikertQstn4
      ).toLowerCase();

      if (
        text.includes("long-sleeved shirt") &&
        (desc2 === "rarely" || desc2 === "sometimes" || desc2 === "never")
      ) {
        retVal = text.replace(
          "{a}",
          C2DataSubmissionService.c2Ch3DynamicTextCLSG1
        );
        retVal = retVal.replace(
          "{b}",
          C2DataSubmissionService.c2Ch3DynamicTextCLSG2
        );
        return retVal;
      }

      if (text.includes("long-sleeved shirt") && desc2 === "often") {
        retVal = text.replace(
          "{a}",
          C2DataSubmissionService.c2Ch3DynamicTextCLSG3
        );
        retVal = retVal.replace(
          "{b}",
          C2DataSubmissionService.c2Ch3DynamicTextCLSG4
        );

        if (desc3 === "often" && desc4 === "often") {
          retVal =
            "You’re on the right track with these sun-safe behaviors. mySmartSkin will help you engage in these behaviors more often.";
        }
        return retVal;
      }

      if (text.includes("long-sleeved shirt") && desc2 === "always") {
        retVal = text.replace(
          "{a}",
          C2DataSubmissionService.c2Ch3DynamicTextCLSG5
        );
        retVal = retVal.replace(
          "{b}",
          C2DataSubmissionService.c2Ch3DynamicTextCLSG6
        );
        if (desc3 === "always" && desc4 === "always") {
          retVal =
            "It’s great that you regularly protect yourself with a long-sleeved shirt, wide-brimmed hat, and sunglasses! Remember to also use SPF 30+ sunscreen and seek shade when possible. Keep up the good work!";
        }
        return retVal;
      }

      if (
        text.includes("wide-brimmed hat") &&
        (desc3 === "rarely" || desc3 === "sometimes" || desc3 === "never")
      ) {
        retVal = text.replace(
          "{a}",
          C2DataSubmissionService.c2Ch3DynamicTextBH1
        );
        retVal = retVal.replace(
          "{b}",
          C2DataSubmissionService.c2Ch3DynamicTextBH2
        );
        return retVal;
      }

      if (text.includes("wide-brimmed hat") && desc3 === "often") {
        retVal = text.replace(
          "{a}",
          C2DataSubmissionService.c2Ch3DynamicTextBH3
        );
        retVal = retVal.replace(
          "{b}",
          C2DataSubmissionService.c2Ch3DynamicTextBH4
        );

        if (desc2 === "often" && desc4 === "often") {
          retVal = "";
        }
        return retVal;
      }

      if (text.includes("wide-brimmed hat") && desc3 === "always") {
        retVal = text.replace(
          "{a}",
          C2DataSubmissionService.c2Ch3DynamicTextBH5
        );
        retVal = retVal.replace(
          "{b}",
          C2DataSubmissionService.c2Ch3DynamicTextBH6
        );

        if (desc2 === "always" && desc4 === "always") {
          retVal = "";
        }

        return retVal;
      }

      if (
        text.includes("Wearing sunglasses") &&
        (desc4 === "rarely" || desc4 === "sometimes" || desc4 === "never")
      ) {
        retVal = text.replace(
          "{a}",
          C2DataSubmissionService.c2Ch3DynamicTextWS1
        );
        retVal = retVal.replace(
          "{b}",
          C2DataSubmissionService.c2Ch3DynamicTextWS2
        );
        retVal = retVal.replace("Wearing", "wearing");
        retVal = retVal.replace("sunglasses !", "sunglasses!");
        return retVal;
      }

      if (text.includes("Wearing sunglasses") && desc4 === "often") {
        retVal = text.replace(
          "{a}",
          C2DataSubmissionService.c2Ch3DynamicTextWS3
        );
        retVal = retVal.replace(
          "{b}",
          C2DataSubmissionService.c2Ch3DynamicTextWS4
        );

        if (desc2 === "often" && desc3 === "often") {
          retVal = "";
        }
        retVal = retVal.replace("Wearing", "wearing");
        retVal = retVal.replace("sunglasses !", "sunglasses!");
        return retVal;
      }

      if (text.includes("Wearing sunglasses") && desc4 === "always") {
        retVal = text.replace(
          "{a}",
          C2DataSubmissionService.c2Ch3DynamicTextWS5
        );
        retVal = retVal.replace(
          "{b}",
          C2DataSubmissionService.c2Ch3DynamicTextWS6
        );

        if (desc2 === "always" && desc3 === "always") {
          retVal = "";
        }
        retVal = retVal.replace("Wearing", "wearing");
        retVal = retVal.replace("sunglasses !", "sunglasses!");
        return retVal;
      }

      // if (desc2 === "often" || desc2==="always" && desc3 ==="often" || desc3==="always" && desc4==="often" || desc4==="always"){
      //   retVal = text.replace(
      //     "{a}",
      //     C2DataSubmissionService.c2Ch3DynamicTextoa1
      //   );
      //           return retVal;
      // }
      // if (desc2==="always" && desc3==="always" && desc4==="always"){
      //   retVal = text.replace(
      //     "{a}",
      //     C2DataSubmissionService.c2Ch3DynamicTextoa2
      //   );
      //           return retVal;
      // }

      var desc5 = C2DataSubmissionService.getLikertDescriptionFromIndex(
        C2DataSubmissionService.c2Ch1S3LikertQstn5
      ).toLowerCase();

      var desc6 = C2DataSubmissionService.getLikertDescriptionFromIndex(
        C2DataSubmissionService.c2Ch1S3LikertQstn6
      ).toLowerCase();

      if (
        text.includes(
          "mySmartSkin has feedback for you based on your behaviors of <strong>{x}</strong> avoiding the sun from 10 a.m. to 4 p.m. and <strong>{y}</strong> staying in the shade or under an umbrella on warm, sunny days."
        )
      ) {
        retVal = text.replace(
          "{x}",
          C2DataSubmissionService.getLikertDescriptionFromIndex(
            C2DataSubmissionService.c2Ch1S3LikertQstn5
          ).toLowerCase()
        );
        retVal = retVal.replace(
          "{y}",
          C2DataSubmissionService.getLikertDescriptionFromIndex(
            C2DataSubmissionService.c2Ch1S3LikertQstn6
          ).toLowerCase()
        );
        return retVal;
      }
      if (
        text.includes("{a} When you can't avoid being outdoors, {b}.") &&
        (desc5 === "never" || desc5 === "rarely" || desc5 === "sometimes")
      ) {
        retVal = text.replace(
          "{a}",
          C2DataSubmissionService.c2Ch3DynamicTextas1
        );
        retVal = retVal.replace(
          "{b}",
          C2DataSubmissionService.c2Ch3DynamicTextas2
        );
        retVal.replace("..", ".");
        return retVal;
      }

      if (
        text.includes("{a} When you can't avoid being outdoors, {b}.") &&
        desc5 === "often"
      ) {
        retVal = text.replace(
          "{a}",
          C2DataSubmissionService.c2Ch3DynamicTextas3
        );
        retVal = retVal.replace(
          "{b}",
          C2DataSubmissionService.c2Ch3DynamicTextas4
        );

        if (desc6 === "often" || desc6 === "always") {
          retVal =
            "You’re on the right track trying to limit your sun exposure. mySmartSkin will help you identify and overcome challenges to limiting your sun exposure. ";
        }
        retVal.replace("..", ".");
        return retVal;
      }

      if (
        text.includes("{a} When you can't avoid being outdoors, {b}.") &&
        desc5 === "always"
      ) {
        retVal = text.replace(
          "{a}",
          C2DataSubmissionService.c2Ch3DynamicTextas5
        );
        retVal = retVal.replace(
          "{b}",
          C2DataSubmissionService.c2Ch3DynamicTextas6
        );

        //If desc5 both often or one often or one always
        // if (desc5 === "often" || desc6 === "often" || desc5 === "always"  || desc6 === "often" || desc6 === "always") {
        //   retVal =
        //     "It’s great that you regularly limit your sun exposure by avoiding the sun during peak ultraviolet (UV) times and seeking shade! Remember to also use SPF 30+ sunscreen and wear sun-safe clothing and sunglasses when possible. Keep up the good work!";
        // }
        // return retVal;

        if (desc6 === "often") {
          retVal =
            "You’re on the right track trying to limit your sun exposure. mySmartSkin will help you identify and overcome challenges to limiting your sun exposure.";
        }

        //If desc6 and 5 are always
        if (desc6 === "always") {
          retVal =
            "It’s great that you regularly limit your sun exposure by avoiding the sun during peak ultraviolet (UV) times and seeking shade! Remember to also use SPF 30+ sunscreen and wear sun-safe clothing and sunglasses when possible. Keep up the good work!";
        }
        retVal.replace("..", ".");
        return retVal;
      }

      //  var desc6 = C2DataSubmissionService.getLikertDescriptionFromIndex(
      //     C2DataSubmissionService.c2Ch1S3LikertQstn6
      //   ).toLowerCase();
      if (
        text.includes("sun-safe behaviors for best protection.") &&
        (desc6 === "never" || desc6 === "rarely" || desc6 === "sometimes")
      ) {
        retVal = text.replace(
          "{a}",
          C2DataSubmissionService.c2Ch3DynamicTextss1
        );
        retVal = retVal.replace(
          "{b}",
          C2DataSubmissionService.c2Ch3DynamicTextss2
        );
        retVal.replace("..", ".");
        return retVal;
      }
      if (
        text.includes("sun-safe behaviors for best protection.") &&
        desc6 === "often"
      ) {
        retVal = text.replace(
          "{a}",
          C2DataSubmissionService.c2Ch3DynamicTextss3
        );
        retVal = retVal.replace(
          "{b}",
          C2DataSubmissionService.c2Ch3DynamicTextss4
        );
        if (desc5 === "often" || desc5 === "always") {
          retVal = "";
        }
        retVal.replace("..", ".");
        return retVal;
      }
      if (
        text.includes("sun-safe behaviors for best protection.") &&
        desc6 === "always"
      ) {
        retVal = text.replace(
          "{a}",
          C2DataSubmissionService.c2Ch3DynamicTextss5
        );
        retVal = retVal.replace(
          "{b}",
          C2DataSubmissionService.c2Ch3DynamicTextss6
        );

        if (desc5 === "often") {
          retVal = "";
        }

        //If desc6 and 5 are always
        if (desc5 === "always") {
          retVal = "";
        }
        retVal.replace("..", ".");
        return retVal;
      }

      if (text.includes("Good work on committing to your goal of")) {
        retVal = text.replace(
          "{x}",
          DataSubmissionService.c2A1LatestGoalTitle.toUpperCase()
        );
        return retVal;
      }
    }

    return retVal;
  };

  const getDataSummariesArray = () => {
    let tempArr: string[] = [];

    if (
      DataService.currentCoreIndex === 1 &&
      DataService.currentChapterIndex === 3 &&
      currentScreenIndex === 5
    ) {
      if (C2DataSubmissionService.c2Ch4S3SunSafePriorityOpts.length > 0) {
        tempArr = C2DataSubmissionService.c2Ch4S3SunSafePriorityOpts;

        if (
          tempArr[tempArr.length - 1].toLowerCase().includes("enter my own") ||
          tempArr[tempArr.length - 1]
            .toLowerCase()
            .includes("think of any reasons")
        ) {
          const filArr = tempArr.filter(
            (option, i) =>
              !option.toLowerCase().includes("enter my own") &&
              !option.toLowerCase().includes("think of any reasons")
          );
          tempArr = filArr;
          console.log(filArr);
        }
      }
      if (C2DataSubmissionService.c2Ch4S3SunSafePriorityStrgs.length > 0) {
        tempArr = [
          ...tempArr,
          ...C2DataSubmissionService.c2Ch4S3SunSafePriorityStrgs,
        ];
      }
    }

    if (
      DataService.currentCoreIndex === 1 &&
      DataService.currentChapterIndex === 3 &&
      currentScreenIndex === 4
    ) {
      if (C2DataSubmissionService.c2Ch4S4SunSafeImpOpts.length > 0) {
        tempArr = C2DataSubmissionService.c2Ch4S4SunSafeImpOpts;

        if (
          tempArr[tempArr.length - 1].toLowerCase().includes("enter my own") ||
          tempArr[tempArr.length - 1]
            .toLowerCase()
            .includes("think of any reasons")
        ) {
          const filArr = tempArr.filter(
            (option, i) =>
              !option.toLowerCase().includes("enter my own") &&
              !option.toLowerCase().includes("think of any reasons")
          );
          tempArr = filArr;
          console.log(filArr);
        }
      }
      if (C2DataSubmissionService.c2Ch4S4SunSafeImpStrgs.length > 0) {
        tempArr = [
          ...tempArr,
          ...C2DataSubmissionService.c2Ch4S4SunSafeImpStrgs,
        ];
      }
    }

    return tempArr;
  };

  const getc2A1SummaryPoints = () => {
    let savedGoals = DataSubmissionService.c2A1SkinSelfCheckGoals.goals;
    let tempGoalTitles: string[] = [];

    savedGoals.forEach((goal: any) => {
      if (goal.mcqs[0].question !== "") {
        tempGoalTitles.push(goal.title);
      }
    });

    return tempGoalTitles;
  };

  const c2A1GoalClick = (selectedGoal: string, goalOptions: any[]) => {
    DataSubmissionService.c2A1LatestGoalTitle = selectedGoal;

    if (DataSubmissionService.c2A1SkinSelfCheckGoals.goals.length === 0) {
      let tempGoals: c2A1GoalType[] = [];
      goalOptions.forEach((option: any, i: number) => {
        let obj: c2A1GoalType = {
          title: option.text,
          mcqs: [
            {
              question: "",
              answers: [],
              strategies: [],
            },
          ],
        };

        tempGoals.push(obj);
        DataSubmissionService.c2A1SkinSelfCheckGoals.goals = tempGoals;
      });
      console.log("dsfsdfs");
    }
  };

  const formHandler = (
    question: string,
    selectedOptions: string[],
    selectedStrategies: string[]
  ) => {
    if (selectedOptions.length > 0) {
      setEnableNextBtn(true);
    } else {
      setEnableNextBtn(false);
    }

    let obj = {
      question: question,
      answers: selectedOptions,
      strategies: selectedStrategies,
    };

    let selectedGoal = DataSubmissionService.c2A1LatestGoalTitle;

    let savedDSGoals = DataSubmissionService.c2A1SkinSelfCheckGoals;

    let filteredGoals =
      DataSubmissionService.c2A1SkinSelfCheckGoals.goals.filter(
        (goal) => selectedGoal === goal.title
      );

    //If goal exist with current title
    if (
      filteredGoals !== null &&
      filteredGoals !== undefined &&
      filteredGoals.length > 0
    ) {
      let filteredGoal = filteredGoals[0];
      let filteresGoalMCQS = filteredGoal.mcqs;

      //If mcqs exist with current goal
      if (filteresGoalMCQS.length > 0) {
        if (
          filteresGoalMCQS.length === 1 &&
          filteresGoalMCQS[0].question === ""
        ) {
          filteresGoalMCQS.pop();
          filteresGoalMCQS.push(obj);
        } else {
          let questionMatched = false;
          filteresGoalMCQS.forEach((mcq: any, i: number) => {
            //If question exists in mcqs
            if (
              (mcq.question as string)
                .replaceAll("<strong>", "")
                .replaceAll("</strong>", "") ===
              (question as string)
                .replaceAll("<strong>", "")
                .replaceAll("</strong>", "")
            ) {
              questionMatched = true;
              filteresGoalMCQS[i] = obj;
            }
          });

          // If question doesn't match, then push the object(question)
          if (!questionMatched) {
            filteresGoalMCQS.push(obj);
          }
        }
      }
    }

    let savedGoals = DataSubmissionService.c2A1SkinSelfCheckGoals;
    console.log(savedGoals);
  };

  const checkContinueBtnEnable = () => {
    if (
      currentCoreScreen.footerButtons !== null &&
      currentCoreScreen.footerButtons.length > 0
    ) {
      setEnableNextBtn(!currentCoreScreen.footerButtons[0].isDisabled);
      console.log(enableNextBtn);
    }
  };

  const resetHiddenContents = () => {
    var tempCurrScreen = currentCoreScreen;
    // var tempCurrScreeContents = [];
    tempCurrScreen.contents.map((content: any, i: number) => {
      if (content.type === actualCurrentScreenContents[0]) {
        var ct = content.type + "-hidden";
        content.type = ct;
      }
    });
    setCurrentCoreScreen(tempCurrScreen);
  };

  const unhideContent = () => {
    var currentCoreScreenTemp = currentCoreScreen;
    var currentCoreScreenContents: any[] = [];

    currentCoreScreenTemp.contents.map((content: any, i: number) => {
      var contentType: string = content.type;
      if (contentType.includes("-hidden")) {
        var replacedContentType = contentType.replace("-hidden", "");
        actualCurrentScreenContents.push(replacedContentType);
        content.type = replacedContentType;
        currentCoreScreenContents.push(content);
      } else {
        currentCoreScreenContents.push(content);
      }
    });

    currentCoreScreenTemp.contents = currentCoreScreenContents;
    setCurrentCoreScreen(currentCoreScreenTemp);
    setRefreshContents(!refreshContents);
  };

  const navigateBack = () => {
    // resetCore2Ch3Content();
    // resetCore2Ch3TanContent();
    accordianSelectedIndex = -1;

    if (currentScreenIndex === skippedScreenIndex && screensSkipped > 0) {
      currentScreenIndex -= screensSkipped; //+ 1;
      screensSkipped = 0;
      skippedScreenIndex = -1;
    } else {
      currentScreenIndex -= 1;

      // Temp Solution
      if (currentScreenIndex === 4) {
        if (
          currentCoreScreens[currentScreenIndex].title ===
          "Identify Your Barriers"
        ) {
          DataSubmissionService.c1A1CameBackFromBarrierSolutions = true;
        }
      }
    }

    if (
      "skipScreensBack" in currentCoreScreen &&
      currentCoreScreen.skipScreensBack !== null &&
      currentCoreScreen.skipScreensBack > 0
    ) {
      currentScreenIndex = currentCoreScreen.skipScreensBack;
      screensSkipped = 0;
      skippedScreenIndex = -1;
    }

    if (currentScreenIndex >= 0) {
      resetHiddenContents();
      setCurrentCoreScreen(currentCoreScreens[currentScreenIndex]);

      let currScreen = currentCoreScreens[currentScreenIndex];

      if (
        currScreen.contents.length > 1 &&
        "text" in currScreen.contents[1] &&
        currScreen.contents[1].text === "Barriers & Solutions"
      ) {
        checkEnableButtonForBarrierSolutions();
      }

      if (currScreen.footerButtons.length > 0) {
        setEnableNextBtn(!currScreen.footerButtons[0].isDisabled);
        console.log(enableNextBtn);
      }
    } else {
      UserService.controlBottomBarFromBack = 1;
      router.goBack();
    }
  };

  const navigateBackToCores = () => {
    currentScreenIndex = 0;
    UserService.controlBottomBarFromBack = 1;
    router.goBack();
    // router.push("home/corestab", "forward", "push");
  };

  const navigateBackToChapter = () => {
    currentScreenIndex = 0;
    DataService.currentCoreScreens = DataService.getScreens(
      DataService.currentCoreIndex,
      DataService.currentChapterIndex
    );
    if (DataService.currentCoreScreens) {
      resetHiddenContents();
      setCurrentCoreScreen(DataService.currentCoreScreens[0]);
      let currScreen = currentCoreScreens[currentScreenIndex];
      if (currScreen.footerButtons.length > 0) {
        setEnableNextBtn(!currScreen.footerButtons[0].isDisabled);
        console.log(enableNextBtn);
      }
    }
  };

  const navigateToNextChapter = () => {
    currentScreenIndex = 0;
    DataService.currentChapterIndex += 1;
    DataService.currentCoreScreens = DataService.getScreens(
      DataService.currentCoreIndex,
      DataService.currentChapterIndex
    );
    if (DataService.currentCoreScreens) {
      resetHiddenContents();
      setCurrentCoreScreen(DataService.currentCoreScreens[0]);
      // let currScreen = currentCoreScreens[currentScreenIndex];
      let currScreen = DataService.currentCoreScreens[currentScreenIndex];
      if (currScreen.footerButtons.length > 0) {
        setEnableNextBtn(!currScreen.footerButtons[0].isDisabled);
        console.log(enableNextBtn);
      }
    }
  };

  const saveprizesredeemed = () => {
    //DataService.isPrizeRedeemed = true;
    var submissionObjt: any = {};
    submissionObjt["allselectedPrizes"] = [];
    var obj: any = {};
    var tempArr: any = [
      {
        id: 4,
        text: "Ruler",
        isSelected: true,
        imagePath: "/assets/images/Ruler_Big.png",
      },
      {
        id: 2,
        text: "Planner",
        isSelected: true,
        imagePath: "/assets/images/Calender Magnet.png",
      },
      {
        id: 1,
        text: "Hanging Door Sign",
        isSelected: true,
        imagePath: "/assets/images/Door Sign.svg",
      },
      {
        id: 3,
        text: "Reminder Stickers",
        isSelected: true,
        imagePath: "/assets/images/Sticky.png",
      },
    ];
    obj["title"] = "Section 1";
    obj["selectedPrizes"] = [];
    for (var i = 0; i < tempArr.length; i++) {
      var localprize = tempArr[i];
      if (localprize.isSelected === true) {
        obj["selectedPrizes"].push({
          value: localprize.text,
          id: localprize.id,
        });
      }
    }
    submissionObjt["allselectedPrizes"].push(obj);
    console.log("submission json", submissionObjt);
    postPrizeData(submissionObjt);
  };

  async function postPrizeData(obj: any) {
    try {
      setLoaderText("Claiming Section 1 Prizes")
      openLoader();
      const responseData = await postSelectedPrizeData(
        obj,
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            //Save user data
            console.log(" " + respData);
            SkinSelfCheckDataService.showFirstSSERemainderFlag = true;
            setShowRewardsPopover({
              open: true,
              event: undefined,
            });
            // DataService.currentCoreIndex += 1;
            // DataService.currentChapterIndex = 0;
            // let core2Ch1Screens = DataService.getScreens(
            //   DataService.currentCoreIndex,
            //   DataService.currentChapterIndex
            // );
            // console.log(core2Ch1Screens);
            // DataService.currentCoreScreens = core2Ch1Screens;
            // if (DataService.currentCoreScreens) {
            //   resetHiddenContents();
            //   setCurrentCoreScreen(DataService.currentCoreScreens[0]);
            //   let currScreen = currentCoreScreens[currentScreenIndex];
            //   if (currScreen.footerButtons.length > 0) {
            //     setEnableNextBtn(!currScreen.footerButtons[0].isDisabled);
            //     console.log(enableNextBtn);
            //   }
            // }
          } else if (!isSuccess) {
            //Error
            closeLoader();
            console.log(" posting prize failure--" + respData);
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Error posting prize:", error);
    }
  }

  const navigateToNextCore = () => {
    currentScreenIndex = 0;
    console.log("showSSERewardFlag ", SkinSelfCheckDataService.showSection1RewardFlag);
    if (DataService.currentCoreIndex === 0) {
      if (SkinSelfCheckDataService.showSection1RewardFlag) {
        saveprizesredeemed()
      } else {
        if (SkinSelfCheckDataService.showFirstSSERemainderFlag) {
          setShowRewardsPopover({
            open: true,
            event: undefined,
          });
        } else {
          DataService.currentCoreIndex += 1;
          DataService.currentChapterIndex = 0;
          let core2Ch1Screens = DataService.getScreens(
            DataService.currentCoreIndex,
            DataService.currentChapterIndex
          );
          console.log(core2Ch1Screens);
          DataService.currentCoreScreens = core2Ch1Screens;
          if (DataService.currentCoreScreens) {
            resetHiddenContents();
            setCurrentCoreScreen(DataService.currentCoreScreens[0]);
            let currScreen = currentCoreScreens[currentScreenIndex];
            if (currScreen.footerButtons.length > 0) {
              setEnableNextBtn(!currScreen.footerButtons[0].isDisabled);
              console.log(enableNextBtn);
            }
          }
        }

      }
    } else {
      DataService.currentCoreIndex += 1;
      DataService.currentChapterIndex = 0;
      let core2Ch1Screens = DataService.getScreens(
        DataService.currentCoreIndex,
        DataService.currentChapterIndex
      );
      console.log(core2Ch1Screens);
      DataService.currentCoreScreens = core2Ch1Screens;
      if (DataService.currentCoreScreens) {
        resetHiddenContents();
        setCurrentCoreScreen(DataService.currentCoreScreens[0]);
        let currScreen = currentCoreScreens[currentScreenIndex];
        if (currScreen.footerButtons.length > 0) {
          setEnableNextBtn(!currScreen.footerButtons[0].isDisabled);
          console.log(enableNextBtn);
        }
      }
    }

    console.log("on Click next core");
  };

  const navigateToNextLesson = () => {
    isLesson = true;
    currentScreenIndex = 0;
    DataService.currentLessonIndex += 1;
    DataService.currentCoreScreens = DataService.getLessonScreens(
      DataService.currentCoreIndex,
      DataService.currentChapterIndex,
      DataService.currentLessonIndex
    );
    if (DataService.currentCoreScreens) {
      resetHiddenContents();
      setCurrentCoreScreen(DataService.currentCoreScreens[0]);
      let currScreen = currentCoreScreens[currentScreenIndex];
      if (currScreen.footerButtons.length > 0) {
        setEnableNextBtn(!currScreen.footerButtons[0].isDisabled);
        console.log(enableNextBtn);
      }
    }
  };

  const navigatetoLesson = (
    screens: any,
    coreNo: number,
    chapterNo: string
  ) => {
    currentScreenIndex = 0;
    DataService.currentLessonIndex = Number(chapterNo.split(" ")[1]) - 1;
    DataService.currentCoreScreen = screens[0];
    DataService.currentCoreScreens = screens;
    currentCoreScreens = DataService.currentCoreScreens;
    resetHiddenContents();
    setCurrentCoreScreen(currentCoreScreens[currentScreenIndex]);
    let currScreen = currentCoreScreens[currentScreenIndex];
    if (currScreen.footerButtons.length > 0) {
      setEnableNextBtn(!currScreen.footerButtons[0].isDisabled);
      console.log(enableNextBtn);
    }
  };

  function dismiss() {
    modal.current?.dismiss();
  }

  const resetSavedGoals = () => {
    DataSubmissionService.c1A1SkinSelfCheckImportanceRating = -1;
    DataSubmissionService.c1A1SkinSelfCheckImportanceRatingDesc = "";
    DataSubmissionService.c1A1SkinSelfCheckReasons = [];
    // DataSubmissionService.chooseAGoalOption.options = [];
    // DataSubmissionService.chooseAGoalOption = null;
    DataSubmissionService.chooseAGoalOptionMainQuestionIndex = -1; //Not required for submission to BE
    DataSubmissionService.c1A1SkinSelfCheckBarriers = [];
    // DataSubmissionService.c1A1SkinSelfCheckBarriersSolutions = [];
    DataSubmissionService.c1A1SkinSelfCheckMaterials = [];
    DataSubmissionService.c1A1SkinSelfCheckHaveMaterials = [];

    DataSubmissionService.c1A1SkinSelfCheckBarriersSolutionsAllOptions.map(
      (option: any, i: number) => {
        option.isViewed = false;
      }
    );
    DataSubmissionService.c1A1SkinSelfCheckBarriersSolutions.map(
      (option: any, i: number) => {
        option.isViewed = false;
      }
    );
    DataSubmissionService.c1A1SkinSelfCheckBarriersSolutions = [];

    let goalOption = DataSubmissionService.chooseAGoalOption;
    if (
      goalOption !== undefined &&
      DataSubmissionService.chooseAGoalOption.options !== undefined &&
      DataSubmissionService.chooseAGoalOption.options.length > 0
    ) {
      DataSubmissionService.chooseAGoalOption.options.map((option: any) => {
        option.isSelected = false;
        option.isThere = false;
      });
    }
  };

  const getDesc = (title: string, desc: string) => {
    let retVal = desc;
    if (title === "What you've accomplished") {
      let tempDesc = desc;

      let goal = DataSubmissionService.chooseAGoalOption;
      tempDesc = tempDesc.replace("{x}", goal.options[0].summaryGoal);
      tempDesc = tempDesc.replace("{y}", goal.options[0].summaryGoalOption);

      retVal = tempDesc;
    }

    return retVal;
  };

  const getBarriersNo = (desc: string) => {
    if (desc.includes("you have selected")) {
      if (DataSubmissionService.noneOfTheseSelected) {
        return "Review this list of common barriers with strategies for overcoming them. <strong>If you have other barriers,</strong> find ways that you can overcome them to <strong>achieve your goal.</strong>";
      }

      var retVal = "";
      let tempDesc = desc;

      // tempDesc = tempDesc.replace(
      //   "{x}",
      //   DataSubmissionService.c1A1SkinSelfCheckBarriersSolutions.length.toString()
      // );
      // if (DataSubmissionService.c1A1SkinSelfCheckBarriersSolutions.length > 1) {
      //   tempDesc = tempDesc.replaceAll("barrier", "barriers");
      // }

      retVal = tempDesc;
      return retVal;
    } else {
      return desc;
    }
  };

  const getBarrierOptions = (contentOptions: Array<any>) => {
    const tempContentOpts = contentOptions.slice(0, -1);

    const neededContentIndexes = DataSubmissionService.c1A1BarrierIndexes;
    const retVal = [];
    for (let i = 0; i < neededContentIndexes.length; i++) {
      retVal.push(tempContentOpts[neededContentIndexes[i]]);
    }

    return retVal;
  };

  const checkEnableButtonForBarrierSolutions = () => {
    let savedBarrSolns =
      DataSubmissionService.c1A1SkinSelfCheckBarriersSolutions;

    let enableContinueBtn = true;
    for (let index = 0; index < savedBarrSolns.length; index++) {
      if (index === 0) {
        (savedBarrSolns[index] as any).isViewed = true;
      }
      if (!(savedBarrSolns[index] as any).isViewed) {
        enableContinueBtn = false;
      }
    }
    setTimeout(() => {
      setEnableNextBtn(enableContinueBtn);
    }, 500);
  };

  const getBarrierSolutionsUI = () => {
    return (
      <>
        {DataSubmissionService.c1A1SkinSelfCheckBarriersSolutions.map(
          (option: any, i: number) => {
            if (option !== undefined) {
              return (
                <AccordianBarrierSolution
                  solIsViewedCallback={(viewed: boolean) => {
                    let sols =
                      DataSubmissionService.c1A1SkinSelfCheckBarriersSolutions[
                      i
                      ];
                    console.log(sols);
                    if (viewed) {
                      (sols as any).isViewed = viewed;
                      setRefreshContents(!refreshContents);
                    }

                    let solutions =
                      DataSubmissionService.c1A1SkinSelfCheckBarriersSolutions;
                    setEnableNextBtn(true);
                    for (let index = 0; index < solutions.length; index++) {
                      if (!(solutions[index] as any).isViewed) {
                        setEnableNextBtn(false);
                      }
                    }
                  }}
                  setOpen={i === 0}
                  isViewed={option.isViewed}
                  isChecked={option.isChecked}
                  text={option.text}
                  desc={option.desc}
                  pointsDesc={option.pointsDesc}
                  points={option.points}
                  textClicked={(text: string) => {
                    if (
                      text ===
                      "Review <span class='text-underline'>mySmartSkin content</span> to increase knowledge and skills"
                    ) {
                      UserService.controlBottomBarFromBack = 1;
                      router.goBack();
                    }
                  }}
                />
              );
            }
          }
        )}
      </>
    );
  };

  const getAccordianGoalSelectedUI = (content: any) => {
    let savedGoal = DataSubmissionService.chooseAGoalOption;
    if (savedGoal.mainDesc === "Gather the right tools") {
      var optionsIsSelected = savedGoal.options.filter(function (goal: any) {
        return goal.isSelected;
      });
      var optionsIsThere = optionsIsSelected.filter(function (goal: any) {
        return goal.isThere;
      });
      var msg = "";
      if (optionsIsSelected.length === optionsIsThere.length) {
        msg = "You already have all the tools";
      } else if (optionsIsSelected.length > 0 && optionsIsThere.length === 0) {
        msg = "You don't have any items you selected";
      }
      return (
        <>
          <AccordianToolsSelected
            title={content.title}
            msg={msg}
            options={
              DataSubmissionService.c1A1SkinSelfCheckHaveMaterialsFinalDataSummary
            }
          />
        </>
      );
    } else {
      var filteredData = savedGoal.options.filter(function (goal: any) {
        return goal.isSelected;
      });
      var goal = filteredData[0];
      return (
        <>
          <AccordianGoalSummary
            text={goal.summaryTitle}
            desc={goal.summaryDesc}
            subDesc={goal.summarySubDesc}
            imgUrl={goal.imageUrl}
          />
        </>
      );
    }
  };

  const getInTheFutureUI = (content: any) => {
    let savedGoal = DataSubmissionService.chooseAGoalOption;
    var filteredData = savedGoal.options.filter(function (goal: any) {
      return goal.isSelected;
    });
    var goal = filteredData[0];
    return (
      <>
        <AccordianSummaryColorText
          title={content.title}
          options={goal.summaryFutureOptions}
        />
      </>
    );
  };

  const navigateToCore1Activity = () => {
    DataService.currentCoreScreens = DataService.getScreens(0, 6); //0,5
    currentScreenIndex = 0;
    DataService.currentCoreScreen =
      DataService.currentCoreScreens[currentScreenIndex];
    DataService.currentChapterIndex = 6; //5
    DataService.currentCoreIndex = 0;
    currentCoreScreens = DataService.currentCoreScreens;

    setCurrentCoreScreen(DataService.currentCoreScreen);
  };

  const navigateToCore2Activity = () => {
    DataService.currentCoreScreens = DataService.getScreens(1, 4); //0,5
    currentScreenIndex = 2;
    DataService.currentCoreScreen =
      DataService.currentCoreScreens[currentScreenIndex];
    DataService.currentChapterIndex = 4; //5
    DataService.currentCoreIndex = 0;
    currentCoreScreens = DataService.currentCoreScreens;

    setCurrentCoreScreen(DataService.currentCoreScreen);
  };

  return (
    <IonPage>
      <IonContent
        fullscreen
        style={{
          "--ion-background-color": "#187685",
          paddingBottom: "90px !important",
        }}
        className={
          // "footerButtons" in currentCoreScreens[currentCoreScreens.length - 1] &&
          currentCoreScreens[currentCoreScreens.length - 1].footerButtons !==
            undefined &&
            currentCoreScreens[currentCoreScreens.length - 1].footerButtons !==
            null &&
            currentCoreScreens[currentCoreScreens.length - 1].footerButtons
              .length > 1 && //Last screen footer button length > 1 && ((Last screen footer button[1] includes lesson) || ())
            ((
              currentCoreScreens[currentCoreScreens.length - 1].footerButtons[1]
                .text as string
            ).includes("Lesson") ||
              ((
                currentCoreScreens[currentCoreScreens.length - 1].footerButtons[1]
                  .text as string
              ).includes("Chapter ") &&
                currentScreenIndex >= 0 &&
                currentCoreScreens[currentScreenIndex] !== undefined &&
                "progressBar" in currentCoreScreens[currentScreenIndex] &&
                currentCoreScreens[currentScreenIndex].progressBar.percentage !==
                100))
            ? "cccIonContentLesson"
            : ""
        }
      >
        <LoadingScreen text={loaderText} isOpen={showLoader} />

        <IonPopover
          id="ccc"
          isOpen={showPopover.open}
          event={showPopover.event}
          onDidDismiss={(e) =>
            setShowPopover({ open: false, event: undefined })
          }
        >
          <IonContent class="ion-padding">
            <IonText
              className="scsdDownloadPDFText1"
              style={{ marginBottom: "8px" }}
            >
              You already selected 2 goals. If you want to change the goal,
              remove atleast 1 goal from below:
            </IonText>
            {c2A1getSelectedGoals().map((goal: string) => {
              return (
                <div>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonText className="scsdDownloadPDFText2">
                          {goal}
                        </IonText>
                      </IonCol>
                      <IonCol
                        size="auto"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <IonImg
                          src="assets/icons/CloseCircleOutlineWhite.png"
                          style={{
                            width: "18px",
                            marginTop: "4px",
                            marginLeft: "4px",
                          }}
                          onClick={() => {
                            let savedGoals =
                              DataSubmissionService.c2A1SkinSelfCheckGoals
                                .goals;
                            let tempGoals: c2A1GoalType[] = [];

                            savedGoals.forEach((gl: c2A1GoalType) => {
                              if (gl.title === goal) {
                                gl.mcqs = [
                                  {
                                    question: "",
                                    answers: [],
                                    strategies: [],
                                  },
                                ];
                              }
                              tempGoals.push(gl);
                            });
                            DataSubmissionService.c2A1SkinSelfCheckGoals.goals =
                              tempGoals;
                            console.log(tempGoals);
                            setRefreshContents(!refreshContents);
                          }}
                        ></IonImg>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              );
            })}
          </IonContent>
        </IonPopover>

        {/* SSE Popover */}
        {DataService.currentCoreIndex === 0 && SkinSelfCheckDataService.showFirstSSERemainderFlag
          && (
            <IonModal
              ref={modal}
              isOpen={showRewardsPopover.open}
              onDidDismiss={() => {
                setShowRewardsPopover({ open: false, event: undefined });
              }}
              className="sscpsReminderContentNew"
              initialBreakpoint={1.0}
              breakpoints={[0, 0.75, 0.8, 1.0]}
            >
              <div className="sscpsReminderContent">
                <div className="sseImageDiv">
                <IonImg className="sseImage"
                  src="assets/images/exposure.png"
                  alt="Centered Image"></IonImg>
                </div>
               
                <IonText
                  className="remind-titlenew">
                  Now that you have learned about the importance of skin self-checks,
                  schedule a day to do your skin self-check!</IonText>

                <div className="buttonFooter">
                  <IonGrid>
                    <IonRow>
                      <IonCol
                        className="ffsscpsButton12Col"
                        size="6"
                        style={{
                          paddingLeft: "16px",
                          paddingRight: "16px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        }}
                      >
                        <IonButton
                          className="loginBtnDefault"
                          style={{
                            width: "100%",
                          }}
                          onClick={() => {
                            setShowRewardsPopover({ open: false, event: undefined });
                            setOpenReminder(true);
                            resetReminderScreens();
                          }}
                        >
                          <IonText className="sscpsReminderButtonsText">
                            Schedule Check
                          </IonText>
                        </IonButton>
                      </IonCol>

                      <IonCol
                        className="ffsscpsButton12Col"
                        size="6"
                        style={{
                          paddingLeft: "16px",
                          paddingRight: "16px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        }}
                      >
                        <IonButton
                          className="loginBtnEnabled ffsscpsButtonNext"
                          style={{
                            width: "100%",
                          }}
                          disabled={disableReminderBtn}
                          onClick={() => {
                            setShowRewardsPopover({ open: false, event: undefined });
                            setTimeout(() => {
                              UserService.controlBottomBarFromBack = 2;
                              SkinSelfCheckDataService.showFirstSSERemainderFlag = false;
                              router.push("home/skinchecktab", "forward", "push");
                          
                            }, 300);
                             }}
                        >
                          <IonText className="sscpsReminderButtonsText">
                            Do check now
                          </IonText>
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>

              </div>
            </IonModal>
          )}

        {/* {DataService.currentCoreIndex !== 0 && SkinSelfCheckDataService.showSSERewardFlag && (<IonPopover
          id="ccc"
          isOpen={showRewardsPopover.open}
          event={showRewardsPopover.event}
          onDidDismiss={(e) => {
            setShowRewardsPopover({ open: false, event: undefined });
          }}
        > */}
          {/* <IonContent class="ion-padding"> */}
            {/* {(DataService.currentCoreIndex === 0) && (
              <IonText
                className="scsdDownloadPDFText1"
                style={{ marginBottom: "8px" }}
              >
                Congratulations! You earned a prize for completing the
                Section 1 in MSS. Click below to choose a prize.
              </IonText>
            )}
            {(DataService.currentCoreIndex === 1) && (
              <IonText
                className="scsdDownloadPDFText1"
                style={{ marginBottom: "8px" }}
              >
                Congratulations! You earned a prize for completing
                the Section 2 in MSS.<br />Click below to choose a prize.
              </IonText>
            )} */}

            {/* <div className="cccRewardsPopoverButtonsContainer"> */}
              {/* <IonText
                onClick={() => {
                  setShowRewardsPopover({ open: false, event: undefined });
                  //UserService.controlBottomBarFromBack=3;
                  DataSubmissionService.isGoalsGetAPICalled = false;
                  //router.push("/home/mystufftab", "root","replace");
                  if (DataService.currentCoreIndex === 0) {
                    DataService.selectedPrizeTitle = "Section 1"
                  } else if (DataService.currentCoreIndex === 1) {
                    DataService.selectedPrizeTitle = "Section 2"

                  }
                  router.push("/RedeemPrizes", "forward", "push");
                  //router.push("/home/mystufftab", "forward");
                  // router.push("/RewardsBadgesPrizes", "forward", "push");
                }}
              >
                Claim Your Prize
              </IonText> */}
              {/* <IonText
                onClick={() => {
                  setShowRewardsPopover({ open: false, event: undefined });
                }}
              >
                No
              </IonText> */}
            {/* </div> */}
          {/* </IonContent> */}
        {/* </IonPopover>)} */}


        {/* Back Button */}
        {showBackButton && currentCoreScreen.showBackButton && (
          <div className="cciBackButtonContainer" onClick={navigateBack}>
            <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
          </div>
        )}
        {/* _______________________________________________________________________ */}
        {/* Progress bar */}
        {currentCoreScreen.progressBar.show && (
          <ProgressContainer
            isActivity={currentCoreScreen.progressBar.isActivity}
            percent={currentCoreScreen.progressBar.percentage}
          ></ProgressContainer>
        )}
        {/* _______________________________________________________________________ */}
        {(currentCoreScreen.showBackButton ||
          currentCoreScreen.showExitButton ||
          currentCoreScreen.title) && (
            <IonGrid>
              {currentCoreScreens[currentCoreScreens.length - 1].footerButtons !==
                null &&
                currentCoreScreens[currentCoreScreens.length - 1].footerButtons
                  .length > 1 &&
                ((
                  currentCoreScreens[currentCoreScreens.length - 1]
                    .footerButtons[1].text as string
                ).includes("Lesson") ||
                  (
                    currentCoreScreens[currentCoreScreens.length - 1]
                      .footerButtons[1].text as string
                  ).includes("Chapter ")) && (
                  <IonRow>
                    <IonCol className="cccLessonTitleCol">
                      <IonText className="cccLessonTitle">
                        {currentCoreScreen.chapterNo}
                      </IonText>
                    </IonCol>
                  </IonRow>
                )}
              <IonRow class="ion-justify-content-between ion-align-items-center cccHeaderRow">
                <IonCol>
                  {currentCoreScreen.showExitButton && (
                    <IonText
                      style={{ color: "blue", marginLeft: "16px" }}
                    ></IonText>
                  )}
                </IonCol>
                <IonCol className="cccTitleCol" size="9">
                  {currentCoreScreen.title.length > 0 && (
                    <IonText className="titleText">
                      {currentCoreScreen.title}
                    </IonText>
                  )}
                </IonCol>
                <IonCol className="cccExitCol">
                  {currentCoreScreen.showExitButton && (
                    <IonText
                      className="cciExitBtn"
                      onClick={() => {
                        DataSubmissionService.isGoalsGetAPICalled = false;
                        UserService.controlBottomBarFromBack = 1;
                        router.goBack();
                      }}
                    >
                      Exit
                    </IonText>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          )}

        {/* _______________________________________________________________________  */}
        {/* _______________________________________________________________________ */}

        {/* Contents Body */}

        <div
          className={
            currentCoreScreen.progressBar.show
              ? currentCoreScreen.backgroundIsWhite
                ? "mainContent cccoMainContent"
                : "mainContentGray cccoMainContent"
              : currentCoreScreen.backgroundIsWhite
                ? "mainContent"
                : "mainContentGray"
          }
        >
          {currentCoreScreen.contents.map((content: any, i: any) => {
            /* Contents Hidden UI */
            if (content.type.includes("hidden")) {
              return <></>;
            }
            /* Contents Side Heading */
            if (content.type === "side-heading") {
              return (
                <IonText
                  className="ion-margin-bottom default-text cccoContentHeading"
                  key={i}
                >
                  {content.text}
                </IonText>
              );
            }
            // ________________________________________________________________
            /* Contents  Side Sub Heading */
            if (content.type === "side-sub-heading") {
              return (
                <div className="ccaDescTextContainer" key={i}>
                  <IonText
                    className="ccaDescText"
                    dangerouslySetInnerHTML={{
                      __html: getSideSubHeadingText(content.text),
                    }}
                  ></IonText>
                </div>
              );
            }
            /* Contents Side Heading 72 */
            if (content.type === "side-heading-72") {
              return (
                <div className="cccSideHeading72TextDiv animate__animated animate__fadeInRight">
                  <IonText
                    className="ion-margin-bottom default-text cccoContentHeading"
                    key={i}
                  >
                    {content.text}
                  </IonText>
                </div>
              );
            }
            // ________________________________________________________________
            /* Contents  Side Sub Heading 16 */
            if (content.type === "side-sub-heading-16") {
              return (
                <div
                  className="ccaDescTextContainer cccSideSubHeading16TextDiv animate__animated animate__fadeInRight"
                  key={i}
                >
                  <IonText
                    className="ccaDescText"
                    dangerouslySetInnerHTML={{ __html: content.text }}
                  ></IonText>
                </div>
              );
            }
            // ________________________________________________________________
            /* Contents Overview Card */
            if (content.type === "overview") {
              return (
                <IonCard className="default-border-radius default-card" key={i}>
                  <IonCardContent>
                    <IonImg src={content.imageUrl}></IonImg>
                    <div className="ccaqOverviewTitletextDiv">
                      <IonText
                        className="default-text text-bold default-padding-top default-padding-bottom default-font overview-points-text"
                        dangerouslySetInnerHTML={{ __html: content.title }}
                      ></IonText>
                    </div>
                    <IonGrid>
                      {content.points.map((point: any, i: any) => {
                        return (
                          <IonRow className="default-text list-row" key={i}>
                            <IonCol size="auto" className="listimage">
                              {" "}
                              {i + 1}
                            </IonCol>
                            <IonCol
                              size="auto"
                            // className="default-font overview-points-text"
                            // {/* dangerouslySetInnerHTML={{ __html: point }} */}
                            >
                              <IonText
                                className="default-font overview-points-text"
                                dangerouslySetInnerHTML={{ __html: point }}
                              ></IonText>
                            </IonCol>
                          </IonRow>
                        );
                      })}
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              );
            }
            // ________________________________________________________________
            /* Contents Image text Accordians */
            if (content.type === "image-text-accordions") {
              return (
                <div key={Math.random()}>
                  {content.contents.map((cont: any, i: any) => {
                    // return (
                    //   <AccordianCoreContent
                    //     key={Math.random()}
                    //     title={cont.title}
                    //     description=""
                    //     imageUrl={cont.imageUrl}
                    //     expansionPoints={cont.points}
                    //     bioExpansionPoints={[""]}
                    //     audioUrl=""
                    //   />
                    // );
                    return getImgTextAccordiansPoints(cont, i);
                  })}
                </div>
              );
            }
            // ________________________________________________________________
            /* Contents Image text bio Accordians */
            if (content.type === "image-text-bio-accordions") {
              return (
                <div key={Math.random()}>
                  {content.contents.map((cont: any, i: any) => {
                    return (
                      <AccordianCoreContent
                        key={Math.random()}
                        title={cont.title}
                        description={cont.description}
                        imageUrl={cont.imageUrl}
                        expansionPoints={[""]}
                        bioExpansionPoints={cont.bio}
                        audioUrl={cont.audioUrl}
                        open={i === accordianSelectedIndex}
                        isOpened={() => {
                          accordianSelectedIndex = i;
                          setRefreshContents(!refreshContents);
                        }}
                      />
                    );
                  })}
                </div>
              );
            }
            // ________________________________________________________________
            /* Contents Image text bio desc Accordion */
            if (content.type === "image-desc-bio-accordion") {
              return (
                <AccordianBioDesc
                  key={Math.random()}
                  title={content.title}
                  imageUrl={content.imageUrl}
                  description={content.description}
                  audioUrl={content.audioUrl}
                  open={i === accordianSelectedIndex}
                  isOpened={() => {
                    openIndividualAccordian(content, i);
                  }}
                />
              );
            }
            // ________________________________________________________________
            /* Contents  MCQ */
            if (content.type === "mcq") {
              return (
                <CoreChapterActivityQuestion
                  key={i}
                  question={content.question}
                  options={content.options}
                  isSelected={(optionsSelected: any) => {
                    console.log("Option Selected: " + optionsSelected);

                    // Use map to create an array of indices
                    const indicesArray: number[] = optionsSelected.map(
                      (value: any, index: number) => (value ? index : -1)
                    );
                    // Use filter to remove the -1 values
                    const trueIndices: number[] = indicesArray.filter(
                      (index) => index !== -1
                    );
                    QuizService.captureMCQ(
                      content.question,
                      content.options[trueIndices[0]].text,
                      currentScreenIndex
                    );

                    setEnableNextBtn(true);
                  }}
                />
              );
            }
            // ________________________________________________________________
            /* Contents Core Chapter End Image */
            if (content.type === "mid-img") {
              return (
                <IonGrid key={i}>
                  <IonRow>
                    <IonCol className="cceTitleImgCol" size="12">
                      <IonImg
                        className="cceTitleImg"
                        src={content.imageUrl}
                      ></IonImg>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              );
            }
            // ________________________________________________________________
            /* Contents Core Chapter End Image */
            if (content.type === "mid-img-small") {
              return (
                <IonGrid
                  key={i}
                  className="animate__animated animate__fadeInRight"
                >
                  <IonRow>
                    <IonCol className="cceTitleImgCol cccTitleImgCol" size="12">
                      <IonImg
                        className="cccTitleImgSmall"
                        src={content.imageUrl}
                      ></IonImg>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              );
            }
            // ________________________________________________________________
            /* Contents Core Chapter End Image */
            if (content.type === "mid-img-small-150") {
              return (
                <IonGrid
                  key={i}
                  className="animate__animated animate__fadeInRight"
                >
                  <IonRow>
                    <IonCol className="cceTitleImgCol cccTitleImgCol" size="12">
                      <IonImg
                        className="cccTitleImgSmall150"
                        src={content.imageUrl}
                      ></IonImg>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              );
            }
            // ________________________________________________________________
            /* Contents Core Chapter End Image */
            if (content.type === "mid-img-small-240") {
              return (
                <IonGrid
                  key={i}
                  className="animate__animated animate__fadeInRight"
                >
                  <IonRow>
                    <IonCol className="cceTitleImgCol cccTitleImgCol" size="12">
                      <IonImg
                        className="cccTitleImgSmall240"
                        src={content.imageUrl}
                      ></IonImg>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              );
            }
            // ________________________________________________________________ll
            /* Contents Core Chapter Mid Heading Main */
            if (content.type === "mid-heading-main") {
              return (
                <IonGrid
                  key={i}
                  className="animate__animated animate__fadeInRight"
                >
                  <IonRow>
                    <IonCol
                      className="cceTitleImgCol cceTitleTextCol"
                      size="12"
                    >
                      <IonText className="cceTitleText">{content.text}</IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              );
            }
            // ________________________________________________________________ll
            /* Contents Core Chapter Mid Heading Main Small */
            if (content.type === "mid-heading-main-small") {
              return (
                <IonGrid
                  key={i}
                  className="animate__animated animate__fadeInRight"
                >
                  <IonRow>
                    <IonCol
                      className="cceTitleImgCol cceTitleTextCol cceTitleTextCol"
                      size="12"
                    >
                      <IonText className="cceTitleText">{content.text}</IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              );
            }
            // ________________________________________________________________
            /* Contents Core Chapter End Mid Heading Sub1 */
            if (content.type === "mid-heading-sub1") {
              return (
                <IonGrid
                  key={i}
                  className="animate__animated animate__fadeInRight"
                >
                  <IonRow>
                    <IonCol
                      className="cceTitleImgCol cceTitleTextCol1"
                      size="12"
                    >
                      <IonText className="cceTitleText1">
                        {content.text}
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              );
            }
            // ________________________________________________________________
            /* Contents Core Chapter End Image Mid Heading Sub2 */
            if (content.type === "mid-heading-sub2") {
              return (
                <IonGrid
                  key={i}
                  className="animate__animated animate__fadeInRight"
                >
                  <IonRow>
                    <IonCol
                      className="cceTitleImgCol cceTitleTextCol2"
                      size="12"
                    >
                      <IonText className="cceTitleText2 mb-32">
                        {content.text}
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              );
            }
            // ________________________________________________________________
            /* Contents Core Chapter End Content Completed Img Text */
            if (content.type === "core-content-completed-img-text") {
              return (
                <div key={i} className="cccCoreContCompletedImgTxtMainDiv">
                  <IonGrid>
                    <IonRow>
                      <IonCol
                        className="cceTitleImgCol cccCccitTitleTextCol"
                        size="12"
                      >
                        <CoreContentCompletedImgText
                          title={content.title}
                          description={getDesc(
                            content.title,
                            content.description
                          )}
                          isImgLeft={content.isImgLeft}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              );
            }
            //____________
            if (content.type === "core-content-completed-img-text-summary") {
              return (
                <div key={i} className="cccCoreContCompletedImgTxtMainDiv">
                  <IonGrid>
                    <IonRow>
                      <IonCol
                        className="cceTitleImgCol cccCccitTitleTextCol"
                        size="12"
                      >
                        <CoreContentCompletedImgTextSummary
                          title={content.title}
                          isImgLeft={content.isImgLeft}
                          selectedGoal={
                            DataSubmissionService.chooseAGoalOption.mainTitle
                          }
                          selectedTask={
                            DataSubmissionService.chooseAGoalOption.mainDesc
                          }
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              );
            }
            //_________________________________________________________________
            if (content.type === "core-content-completed-img-text-setgoal") {
              return (
                <div key={i} className="cccCoreContCompletedImgTxtMainDiv">
                  <IonGrid>
                    <IonRow>
                      <IonCol
                        className="cceTitleImgCol cccCccitTitleTextCol"
                        size="12"
                      >
                        <CoreContentCompletedImgTextSetGoal
                          title={content.title}
                          desc={content.description}
                          textLinkClick={navigateToCore1Activity}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              );
            }
            //_________________________________________________________________
            if (
              content.type === "core-content-completed-img-text-setgoal-core2"
            ) {
              return (
                <div key={i} className="cccCoreContCompletedImgTxtMainDiv">
                  <IonGrid>
                    <IonRow>
                      <IonCol
                        className="cceTitleImgCol cccCccitTitleTextCol"
                        size="12"
                      >
                        <CoreContentCompletedImgTextSetGoal
                          title={content.title}
                          desc={content.description}
                          textLinkClick={navigateToCore2Activity}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              );
            }
            // ________________________________________________________________
            /* Contents Core Lessons */
            if (content.type === "core-lessons") {
              return (
                <div key={i}>
                  {/* {content.contents.map((content: any, index: any) => ( */}
                  <SubAccordion
                    coreNo={999}
                    // key={index}
                    chapterNo={content.chapterNo}
                    title={content.title}
                    isActivity={content.isActivity}
                    progress={content.progress}
                    click={navigatetoLesson}
                    screens={content.screens}
                  />
                  {/* ))} */}
                </div>
              );
            }
            // ________________________________________________________________
            /* Contents Img Text Chevron Accordion */
            if (content.type === "img-text-mainimg-accordion") {
              return (
                <div key={Math.random()}>
                  <AccordianMainImgText
                    title={content.title}
                    imageUrl={content.imageUrl}
                    expMainImgText={content.expMainImgText}
                    expMainImgUrl={content.expMainImgUrl}
                    expansionPoints={content.expansionPoints}
                    open={i === accordianSelectedIndex}
                    isOpened={() => {
                      openIndividualAccordian(content, i);
                    }}
                  />
                </div>
              );
            }
            // ________________________________________________________________
            /* Contents Text Accordion */
            if (content.type === "text-accordion") {
              return (
                <AccordianText
                  key={Math.random()}
                  imageUrl={content.imageUrl}
                  title={content.title}
                  description={content.description}
                  open={i === accordianSelectedIndex}
                  isOpened={() => {
                    openIndividualAccordian(content, i);
                  }}
                />
              );
            }
            // ________________________________________________________________
            /* Contents Img Text Main Img */
            if (content.type === "img-text-mainimg") {
              return (
                <MainImgText
                  key={i}
                  imageUrl={content.imageUrl}
                  mainImgText={content.mainImgText}
                  expansionPoints={content.expansionPoints}
                />
              );
            }
            // ________________________________________________________________
            /* Contents Heading Normal Font Middle Text */
            if (content.type === "heading-normal") {
              return (
                <IonText class="cccHeadingNormalText animate__animated animate__slideInDown 1000ms">
                  {content.text}
                </IonText>
              );
            }
            // ________________________________________________________________
            /* Contents Img Text Main Img */
            if (content.type === "overview-goal") {
              return (
                <IonCard
                  className="default-border-radius default-card animate__animated animate__fadeInLeft animate__fast 1400ms"
                  key={i}
                >
                  <IonCardContent className="cccOverViewCardContent">
                    <IonImg src={content.imageUrl}></IonImg>
                    <p className="default-text text-bold default-padding-top default-padding-bottom cccOverviewGoalDescText">
                      {content.title}
                    </p>
                    <IonGrid>
                      {content.points.map((point: any, i: any) => {
                        return (
                          <IonRow className="default-text list-row" key={i}>
                            <IonCol size="auto" className="listimage">
                              {i + 1}
                            </IonCol>
                            <IonCol size="auto" className="default-font">
                              <IonText
                                className="text-default"
                                dangerouslySetInnerHTML={{ __html: point }}
                              />
                            </IonCol>
                          </IonRow>
                        );
                      })}
                      <IonRow>
                        <IonCol className="cccSetGoalBtnContainerCol">
                          <IonButton
                            className="loginBtnEnabled cccLoginBtnEnabled"
                            onClick={() => {
                              currentScreenIndex += 1;
                              resetSavedGoals();
                              resetHiddenContents();
                              setCurrentCoreScreen(
                                currentCoreScreens[currentScreenIndex]
                              );
                              let currScreen =
                                currentCoreScreens[currentScreenIndex];
                              if (currScreen.footerButtons.length > 0) {
                                setEnableNextBtn(
                                  !currScreen.footerButtons[0].isDisabled
                                );
                                console.log(enableNextBtn);
                              }
                            }}
                          >
                            Set a Goal
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              );
            }
            // ________________________________________________________________
            /* Contents Learn More Img Text */
            if (content.type === "learn-more-img-text") {
              return (
                <LearnMoreImgText
                  key={i}
                  imageUrl={content.imageUrl}
                  text={content.text}
                  click={() => {
                    UserService.controlBottomBarFromBack = 1;
                    router.goBack();
                  }}
                />
              );
            }
            // ________________________________________________________________
            /* Contents Img Text Col*/
            if (content.type === "img-text-col") {
              return (
                <IonGrid key={i} className="cccImgTextColGrid">
                  <IonRow>
                    <IonCol size="auto">
                      <IonImg
                        className="cccImgtextColImg animate__animated animate__fadeInLeft"
                        src={content.imageUrl}
                      />
                    </IonCol>
                    <IonCol className="cccImgTextColTextCol">
                      <IonText
                        className="cccImgTextColText animate__animated animate__fadeInRight"
                        dangerouslySetInnerHTML={{
                          __html: content.text,
                        }}
                      ></IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              );
            }
            // ________________________________________________________________
            /* Contents Img Text Col*/
            if (content.type === "img-text-col-8") {
              return (
                <IonGrid key={i} className="cccImgTextColGrid8">
                  <IonRow>
                    <IonCol size="auto">
                      <IonImg
                        className="cccImgtextColImg animate__animated animate__fadeInLeft"
                        src={content.imageUrl}
                      />
                    </IonCol>
                    <IonCol className="cccImgTextColTextCol">
                      <IonText
                        className="cccImgTextColText animate__animated animate__fadeInRight"
                        dangerouslySetInnerHTML={{
                          __html: getBarriersNo(content.text),
                        }}
                      ></IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              );
            }
            // ________________________________________________________________
            /* Contents Side Heading Small Bold */
            if (content.type === "side-heading-small") {
              return (
                <IonText
                  className="cccSideheadingSmallText animate__animated animate__fadeInLeft"
                  key={i}
                  dangerouslySetInnerHTML={{ __html: content.text }}
                ></IonText>
              );
            }
            // ________________________________________________________________
            /* Contents Side Heading Small Mixed */
            if (content.type === "side-heading-small-mixed") {
              return (
                <IonText
                  className="cccSideheadingSmallMixedText animate__animated animate__fadeInRight animate__fast 1000ms animate__delay-1s    1s"
                  key={i}
                  dangerouslySetInnerHTML={{ __html: content.text }}
                ></IonText>
              );
            }
            // ________________________________________________________________
            /* Contents Five Star Rating */
            if (content.type === "five-star-rating") {
              return (
                <div
                  className="cccFSRContainer animate__animated animate__fadeInUp animate__fast 1000ms"
                  key={i}
                >
                  <FiveStarRating
                    click={(i: number, desc: string) => {
                      console.log("Rating Given >>> " + i);
                      //Core-2 Chapter 4 Sun Safe Star Rating
                      if (
                        DataService.currentCoreIndex === 1 &&
                        DataService.currentChapterIndex === 3
                      ) {
                        QuizService.captureFiveStarRating(
                          currentScreenIndex,
                          i + 1
                        );

                        C2DataSubmissionService.c2Ch4SunSafeImpRating = i + 1;
                        C2DataSubmissionService.c2Ch4SunSafeImpRatingDesc =
                          desc;
                        console.log(
                          "C2Ch4SunSafeRating: " +
                          C2DataSubmissionService.c2Ch4SunSafeImpRating
                        );
                        console.log(
                          "c2Ch4SunSafeImpRatingDesc: " +
                          C2DataSubmissionService.c2Ch4SunSafeImpRatingDesc
                        );
                        setEnableNextBtn(true);
                        return;
                      }

                      DataSubmissionService.c1A1SkinSelfCheckImportanceRating =
                        i + 1;
                      DataSubmissionService.c1A1SkinSelfCheckImportanceRatingDesc =
                        desc;
                      unhideContent();
                    }}
                    descriptions={content.descriptions}
                  />
                </div>
              );
            }
            // ________________________________________________________________
            /* Contents Lesson End */
            if (content.type === "lesson-end") {
              return (
                <IonModal
                  ref={modal}
                  trigger="open-modal"
                  isOpen={lessonEndOpen}
                  initialBreakpoint={0.9}
                  breakpoints={[0, 0.9]}
                >
                  <IonContent className="ion-padding">
                    <IonGrid>
                      <IonRow>
                        <IonCol className="cceTitleImgCol" size="12">
                          <IonImg
                            className="cceTitleImg animate__animated animate__fadeInLeft"
                            src="assets/images/ChapterComplete.png"
                          ></IonImg>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol
                          className="cceTitleImgCol cceTitleTextCol"
                          size="12"
                        >
                          <IonText className="cceTitleText animate__animated animate__fadeInRight">
                            {content.midHeadingMain}
                            <br />
                            {content.midHeadingSub1}
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol
                          className="cceTitleImgCol cceTitleTextCol1"
                          size="12"
                        >
                          <IonText className="cceTitleText1"></IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol
                          className="cceTitleImgCol cceTitleTextCol2"
                          size="12"
                        >
                          <IonText className="cceTitleText2 mb-32">
                            {content.midHeadingSub2}
                          </IonText>
                        </IonCol>
                        <IonCol
                          className="cceTitleImgCol cceTitleTextCol2"
                          size="12"
                        >
                          <IonText className="cceTitleText2 mb-32">
                            {content.midHeadingSub3}
                          </IonText>
                        </IonCol>
                      </IonRow>

                      <div className="buttonFooter customswipeFooter">
                        <IonRow className="ion-justify-content-center">
                          <IonCol
                            className="custom-flex"
                            style={{
                              paddingLeft: "16px",
                              paddingRight: "16px",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                            }}
                          >
                            <IonButton
                              id="open-modal"
                              className="loginBtnDefault"
                              mode="ios"
                              onClick={() => {
                                dismiss();
                                UserService.controlBottomBarFromBack = 1;
                                navigateBackToChapter();
                              }}
                            >
                              <IonImg
                                className="custom-icon ios"
                                src="/assets/images/prev.png"
                              ></IonImg>{" "}
                              Back to Chapter
                            </IonButton>
                            <IonButton
                              className="loginBtnEnabled"
                              mode="ios"
                              onClick={() => {
                                dismiss();
                                navigateToNextLesson();
                              }}
                            >
                              <IonImg
                                className="custom-icon ios"
                                src="/assets/images/next.png"
                              ></IonImg>{" "}
                              Next Lesson
                            </IonButton>{" "}
                          </IonCol>
                        </IonRow>
                      </div>
                    </IonGrid>
                  </IonContent>
                </IonModal>
              );
            }
            // ________________________________________________________________
            /* Contents MCQ Multiple Check */
            if (content.type === "mcq-multiple-check") {
              return (
                <div key={i}>
                  <MCQMultipleCheck
                    question={content.question}
                    desc={content.desc}
                    options={content.multipleOptions}
                    selected={(selectedOptions: Array<string>) => {
                      // DataSubmissionService.c1A1SkinSelfCheckReasons =
                      //   [];
                      DataSubmissionService.c1A1SkinSelfCheckReasons =
                        selectedOptions;

                      setEnableNextBtn(false);
                      if (selectedOptions.length > 0) {
                        setEnableNextBtn(true);
                      }
                    }}
                  />
                </div>
              );
            }
            // ________________________________________________________________
            /* Contents Img Text Col*/
            if (content.type === "img-text-col-bold") {
              return (
                <IonGrid
                  key={i}
                  className="cccImgTextColBoldGrid animate__animated animate__fadeIn"
                >
                  <IonRow>
                    <IonCol>
                      <IonImg
                        src={content.imageUrl}
                        className="mss-custom-image"
                      />
                    </IonCol>
                    <IonCol className="cccImgTextColBoldTextCol">
                      <IonText className="cccImgTextColBoldText">
                        {content.text}
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              );
            }
            // ________________________________________________________________
            /* Contents Heading Center 18 700*/
            if (content.type === "heading-center-18-700") {
              return (
                <IonGrid key={i}>
                  <IonRow>
                    <IonCol
                      className="cceTitleImgCol cccHeadingCenter18700TextCol animate__animated animate__fadeIn 1400ms animate__delay-1s"
                      size="12"
                    >
                      <IonText className="cccHeadingCenter18700Text">
                        {content.text}
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              );
            }
            // ________________________________________________________________
            /* Contents Star Rating Summary*/
            if (content.type === "star-rating-summary") {
              return (
                <div
                  key={i}
                  className="animate__animated animate__fadeInLeft 1800ms"
                >
                  <StarRatingSummary
                    text={content.text}
                    ratingDesc={
                      DataSubmissionService.c1A1SkinSelfCheckImportanceRatingDesc
                    }
                    stars={
                      DataSubmissionService.c1A1SkinSelfCheckImportanceRating
                    }
                  />
                </div>
              );
            }
            // ________________________________________________________________
            /* Contents Selected Points Summary*/
            if (content.type === "selected-points-summary") {
              return (
                <div
                  key={i}
                  className="animate__animated animate__fadeInRight 2000ms animate__delay-1s "
                >
                  <SelectedPointsSummary
                    text={content.text}
                    points={DataSubmissionService.c1A1SkinSelfCheckReasons}
                    isActivitySummaryComp={false}
                  />
                </div>
              );
            }
            /* Contents Description-Summary*/
            if (content.type === "Description-Summary") {
              return <SummaryDescription text={content.text} />;
            }
            /* Contents Description-Summaries*/
            if (content.type === "Description-Summaries") {
              return (
                <>
                  {getDataSummariesArray().map((text: string) => {
                    return (
                      <>
                        <SummaryDescription text={text} />
                        <div style={{ width: "100%", height: "16px" }}></div>
                      </>
                    );
                  })}
                </>
              );
            }
            // ________________________________________________________________
            /* Contents Materials Multiple Select*/
            if (content.type === "materials-multiple-select") {
              return (
                <div key={i}>
                  <MaterialsMultipleSelect
                    mainImgUrl={
                      DataSubmissionService.chooseAGoalOption.mainImgUrl
                    }
                    mainTitle={
                      DataSubmissionService.chooseAGoalOption.mainTitle
                    }
                    mainDesc={DataSubmissionService.chooseAGoalOption.mainDesc}
                    mainDesc2={
                      DataSubmissionService.chooseAGoalOption.mainDesc2
                    }
                    title={DataSubmissionService.chooseAGoalOption.title}
                    imageUrl={DataSubmissionService.chooseAGoalOption.imageUrl}
                    options={DataSubmissionService.chooseAGoalOption.options}
                    showBottomCheckBox={
                      DataSubmissionService.chooseAGoalOption.showBottomCheckBox
                    }
                    click={(options: Array<any>, isMaterials: boolean) => {
                      const enabled = enableNextBtn;
                      setEnableNextBtn(false);
                      if (isMaterials && options.length > 0) {
                        if (options.includes(true)) {
                          setEnableNextBtn(true);
                        }
                      } else {
                        setEnableNextBtn(enabled);
                      }
                      console.log(options);

                      if (isMaterials !== undefined) {
                        if (isMaterials) {
                          let materials: any[] =
                            DataSubmissionService.chooseAGoalOption.options;
                          console.log(materials);

                          options.forEach((option, i) => {
                            // if (option) {
                            materials[i].isSelected = option;
                            // }
                          });
                          DataSubmissionService.chooseAGoalOption.options =
                            materials;
                          console.log(materials);
                        } else {
                          let materialsThere: any[] =
                            DataSubmissionService.chooseAGoalOption.options;
                          console.log(materialsThere);

                          options.forEach((option, i) => {
                            // if (option) {
                            materialsThere[i].isThere = option;
                            // }
                          });
                          DataSubmissionService.chooseAGoalOption.options =
                            materialsThere;
                          console.log(materialsThere);
                        }
                      }

                      let finalMaterialsDataSelected: any[] = [];
                      let finalMaterialsDataThere: any[] = [];
                      DataSubmissionService.chooseAGoalOption.options.forEach(
                        (option: any, i: number) => {
                          if (option.isSelected && !option.isThere) {
                            finalMaterialsDataSelected.push({
                              title: option.title,
                              imageUrl: option.imageUrl,
                            });
                          }
                          if (option.isSelected && option.isThere) {
                            finalMaterialsDataThere.push({
                              title: option.title,
                              imageUrl: option.imageUrl,
                            });
                          }
                        }
                      );
                      DataSubmissionService.c1A1SkinSelfCheckHaveMaterialsFinalDataSummary[0].options =
                        finalMaterialsDataThere;
                      DataSubmissionService.c1A1SkinSelfCheckHaveMaterialsFinalDataSummary[1].options =
                        finalMaterialsDataSelected;
                    }}
                  />
                </div>
              );
            }
            // ________________________________________________________________
            /* Contents Task Selection*/
            if (content.type === "task-selection") {
              return (
                <div key={i}>
                  <TaskSelection
                    question={content.question}
                    //  options={[
                    //   {
                    //     "imageUrl": "assets/images/exposure.png",
                    //     "title": "Start Doing a Skin Self-Check ",
                    //     "subtitle": "(if you don’t do one already)",
                    //     "desc": "Select the task you want to work on",
                    //     "barrierIndexes": [
                    //       0,
                    //       1,
                    //       2,
                    //       3,
                    //       4,
                    //       5
                    //     ],
                    //     "options": [
                    //       {
                    //         "imageUrl": "assets/icons/spanner.png",
                    //         "text": "Gather the right tools",
                    //         "options": {
                    //           "mainImgUrl": "assets/images/exposure.png",
                    //           "mainTitle": "Start Doing a Skin Self-Check",
                    //           "mainDesc": "Gather the right tools",
                    //           "mainDesc2": "<strong>Select the tools</strong> that you use or would like to use to help you with your skin self-check.",
                    //           "title": "Tools",
                    //           "imageUrl": "assets/icons/SpannerOutline.png",
                    //           "showBottomCheckBox": true,
                    //           "options": [
                    //             {
                    //               "imageUrl": "assets/images/HandMirror.png",
                    //               "title": "Hand Mirror",
                    //               "desc": "to view hard to reach places",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Start Doing a skin self-check",
                    //               "summaryGoalOption": "gathering the right tools",
                    //               "summaryFutureOptions": [
                    //                 "Work on <strong>collecting the remaining tools</strong> and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             },
                    //             {
                    //               "imageUrl": "assets/images/Notebook.png",
                    //               "title": "Notebook",
                    //               "desc": "to record suspicious spots",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Start Doing a skin self-check",
                    //               "summaryGoalOption": "gathering the right tools",
                    //               "summaryFutureOptions": [
                    //                 "Work on <strong>collecting the remaining tools</strong> and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             },
                    //             {
                    //               "imageUrl": "assets/images/CellPhone.png",
                    //               "title": "Cell Phone",
                    //               "desc": "to take pictures of suspicious spots",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Start Doing a skin self-check",
                    //               "summaryGoalOption": "gathering the right tools",
                    //               "summaryFutureOptions": [
                    //                 "Work on <strong>collecting the remaining tools</strong> and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             },
                    //             {
                    //               "imageUrl": "assets/images/Ruler.png",
                    //               "title": "Plastic Ruler",
                    //               "desc": "to measure your spots",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Start Doing a skin self-check",
                    //               "summaryGoalOption": "gathering the right tools",
                    //               "summaryFutureOptions": [
                    //                 "Work on <strong>collecting the remaining tools</strong> and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             }
                    //           ]
                    //         }
                    //       },
                    //       {
                    //         "imageUrl": "assets/icons/scan.png",
                    //         "text": "Find someone to help",
                    //         "options": {
                    //           "mainImgUrl": "assets/images/exposure.png",
                    //           "mainTitle": "Start Doing a Skin Self-Check",
                    //           "mainDesc": "Find someone to help",
                    //           "mainDesc2": "<strong>Select which option</strong> you will use to find someone to help.",
                    //           "showBottomCheckBox": false,
                    //           "options": [
                    //             {
                    //               "imageUrl": "assets/images/Family.png",
                    //               "title": "Ask a <strong>family member</strong>",
                    //               "desc": "",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Start Doing a skin self-check",
                    //               "summaryGoalOption": "finding someone to help",
                    //               "summaryTitle": "Find Someone to Help",
                    //               "summaryDesc": "Ask a family member",
                    //               "summarySubDesc": "",
                    //               "summaryFutureOptions": [
                    //                 "<strong>Ask a family to help</strong> you in skin self-check and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             },
                    //             {
                    //               "imageUrl": "assets/images/Friends.png",
                    //               "title": "Ask a friend",
                    //               "desc": "",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Start Doing a skin self-check",
                    //               "summaryGoalOption": "finding someone to help",
                    //               "summaryTitle": "Find Someone to Help",
                    //               "summaryDesc": "Ask a <strong>friend</strong>",
                    //               "summarySubDesc": "",
                    //               "summaryFutureOptions": [
                    //                 "<strong>Ask a friend to help</strong> you in skin self-check and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             }
                    //           ]
                    //         }
                    //       },
                    //       {
                    //         "imageUrl": "assets/icons/hand.png",
                    //         "text": "Set reminders",
                    //         "options": {
                    //           "mainImgUrl": "assets/images/exposure.png",
                    //           "mainTitle": "Start Doing a Skin Self-Check",
                    //           "mainDesc": "Set reminders",
                    //           "mainDesc2": "<strong>Select the option</strong> you will use to remind yourself. ",
                    //           "showBottomCheckBox": false,
                    //           "options": [
                    //             {
                    //               "imageUrl": "assets/images/Reminder.png",
                    //               "title": "Have MSS remind you",
                    //               "desc": "",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Start Doing a skin self-check",
                    //               "summaryGoalOption": "Set reminders",
                    //               "summaryTitle": "Set reminders",
                    //               "summaryDesc": "Have MSS remind you",
                    //               "summarySubDesc": "",
                    //               "summaryFutureOptions": [
                    //                 "<strong>Use the MSS reminders</strong> to remind yourself and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             },
                    //             {
                    //               "imageUrl": "assets/images/CellPhone.png",
                    //               "title": "Set phone reminders",
                    //               "desc": "",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Start Doing a skin self-check",
                    //               "summaryGoalOption": "Set reminders",
                    //               "summaryTitle": "Set reminders",
                    //               "summaryDesc": "Set phone reminders",
                    //               "summarySubDesc": "",
                    //               "summaryFutureOptions": [
                    //                 "<strong>Use phone reminders</strong> to remind yourself and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             },
                    //             {
                    //               "imageUrl": "assets/images/Calander.png",
                    //               "title": "Mark a date on your calendar",
                    //               "desc": "",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Start Doing a skin self-check",
                    //               "summaryGoalOption": "Set reminders",
                    //               "summaryTitle": "Set reminders",
                    //               "summaryDesc": "Mark a date on your calendar ",
                    //               "summarySubDesc": "",
                    //               "summaryFutureOptions": [
                    //                 "<strong>Write the date in your calendar</strong> to remind yourself and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             }
                    //           ]
                    //         }
                    //       }
                    //     ]
                    //   },
                    //   {
                    //     "imageUrl": "assets/images/todo.png",
                    //     "title": "Check All Parts of My Body",
                    //     "subtitle": "(if you want to do a more thorough check)",
                    //     "desc": "Select the task you want to work on",
                    //     "barrierIndexes": [
                    //       3,
                    //       2,
                    //       4,
                    //       5
                    //     ],
                    //     "options": [
                    //       {
                    //         "imageUrl": "assets/icons/ptssc.png",
                    //         "text": "Perform thorough skin self-checks",
                    //         "options": {
                    //           "mainImgUrl": "assets/images/todo.png",
                    //           "mainTitle": "Check All Parts of My Body",
                    //           "mainDesc": "Perform thorough skin self-checks",
                    //           "mainDesc2": "<strong>Select the option</strong> you will use to make sure you are performing thorough skin self-checks.",
                    //           "showBottomCheckBox": false,
                    //           "options": [
                    //             {
                    //               "imageUrl": "assets/images/Body Map.png",
                    //               "title": "<strong>MSS Body Map</strong>",
                    //               "desc": "to make sure I check all body parts",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Check All Parts of My Body",
                    //               "summaryGoalOption": "performing thorough skin self-check",
                    //               "summaryTitle": "Thorough skin self-checks",
                    //               "summaryDesc": "<strong>MSS Body Map</strong>",
                    //               "summarySubDesc": "to make sure I check all body parts",
                    //               "summaryFutureOptions": [
                    //                 "<strong>Use the MSS body map </strong>to check all body parts and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             },
                    //             {
                    //               "imageUrl": "assets/images/Checklist.png",
                    //               "title": "<span class='text-underline'> Separate Checklist</span>",
                    //               "desc": "of body parts",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Check All Parts of My Body",
                    //               "summaryGoalOption": "performing thorough skin self-check",
                    //               "summaryTitle": "Thorough skin self-checks",
                    //               "summaryDesc": "<span class='text-underline'> Separate Checklist</span>",
                    //               "summarySubDesc": "of body parts",
                    //               "summaryFutureOptions": [
                    //                 "<strong>Use a separate checklist </strong>to check all body parts and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             }
                    //           ]
                    //         }
                    //       },
                    //       {
                    //         "imageUrl": "assets/icons/cfma.png",
                    //         "text": "Check frequently missed areas",
                    //         "options": {
                    //           "mainImgUrl": "assets/images/todo.png",
                    //           "mainTitle": "Check All Parts of My Body",
                    //           "mainDesc": "Check frequently missed areas",
                    //           "mainDesc2": "<strong>Select the option</strong> you will use to make sure you check frequently missed areas.",
                    //           "showBottomCheckBox": false,
                    //           "options": [
                    //             {
                    //               "imageUrl": "assets/images/Body Map.png",
                    //               "title": "MSS Body Map",
                    //               "desc": "to make sure I check all body parts",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Check All Parts of My Body",
                    //               "summaryGoalOption": "checking frequently missed areas",
                    //               "summaryTitle": "Check Missed Areas",
                    //               "summaryDesc": "<strong>MSS Body Map</strong>",
                    //               "summarySubDesc": "to make sure I check all body parts",
                    //               "summaryFutureOptions": [
                    //                 "<strong>Use the MSS body map </strong>to check all body parts and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             },
                    //             {
                    //               "imageUrl": "assets/images/Checklist.png",
                    //               "title": "<span class='text-underline'> Separate Checklist</span>",
                    //               "desc": "of body parts",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Check All Parts of My Body",
                    //               "summaryGoalOption": "checking frequently missed areas",
                    //               "summaryTitle": "Check Missed Areas",
                    //               "summaryDesc": "<span class='text-underline'> Separate Checklist</span>",
                    //               "summarySubDesc": "of body parts",
                    //               "summaryFutureOptions": [
                    //                 "<strong>Use a separate checklist </strong>to check all body parts and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             }
                    //           ]
                    //         }
                    //       }
                    //     ]
                    //   },
                    //   {
                    //     "imageUrl": "assets/images/help.png",
                    //     "title": "Make Skin Self-Checks a Habit",
                    //     "subtitle": "(if you don’t do one regularly)",
                    //     "desc": "Select the task you want to work on",
                    //     "barrierIndexes": [
                    //       2,
                    //       4,
                    //       5
                    //     ],
                    //     "options": [
                    //       {
                    //         "imageUrl": "assets/icons/ClockCircleFill.png",
                    //         "text": "Set aside time each month",
                    //         "options": {
                    //           "mainImgUrl": "assets/images/help.png",
                    //           "mainTitle": "Make Skin Self-Checks a Habit",
                    //           "mainDesc": "Set aside time each month",
                    //           "mainDesc2": "<strong>Select the option</strong> you will use to help set aside time each month. ",
                    //           "showBottomCheckBox": false,
                    //           "options": [
                    //             {
                    //               "imageUrl": "assets/images/Reserve a time.png",
                    //               "title": "Reserve a day or time",
                    //               "desc": "such as in the morning on the first Saturday of every month",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Make Skin Self-Checks a Habit",
                    //               "summaryGoalOption": "setting aside time monthly",
                    //               "summaryTitle": "Set Aside Time Monthly",
                    //               "summaryDesc": "Reserve a day or time",
                    //               "summarySubDesc": "such as in the morning on the first Saturday of every month",
                    //               "summaryFutureOptions": [
                    //                 "<strong>Reserve a certain day and time</strong> for your monthly skin self-check and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             },
                    //             {
                    //               "imageUrl": "assets/images/Block time.png",
                    //               "title": "Block off time",
                    //               "desc": "In your planner or diary",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Make skin self-checks a Habit",
                    //               "summaryGoalOption": "setting aside time monthly",
                    //               "summaryTitle": "Set Aside Time Monthly",
                    //               "summaryDesc": "Block off time",
                    //               "summarySubDesc": "In your planner or diary",
                    //               "summaryFutureOptions": [
                    //                 "<strong>Block off the time</strong> for your monthly skin self-check and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             }
                    //           ]
                    //         }
                    //       },
                    //       {
                    //         "imageUrl": "assets/icons/CalendarFilled.png",
                    //         "text": "Set a monthly reminder",
                    //         "options": {
                    //           "mainImgUrl": "assets/images/help.png",
                    //           "mainTitle": "Make Skin Self-Checks a Habit",
                    //           "mainDesc": "Set a monthly reminder",
                    //           "mainDesc2": "<strong>Select the option</strong> you will use to remind yourself to complete your skin self-check each month.",
                    //           "showBottomCheckBox": false,
                    //           "options": [
                    //             {
                    //               "imageUrl": "assets/images/Reminder.png",
                    //               "title": "Have MSS remind you",
                    //               "desc": "",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Make skin self-checks a Habit",
                    //               "summaryGoalOption": "Set a monthly reminder ",
                    //               "summaryTitle": "Set a monthly reminder ",
                    //               "summaryDesc": "Have MSS remind you",
                    //               "summarySubDesc": "",
                    //               "summaryFutureOptions": [
                    //                 "<strong>Use the MSS reminders</strong> to remind yourself and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             },
                    //             {
                    //               "imageUrl": "assets/images/CellPhone.png",
                    //               "title": "Set phone reminders",
                    //               "desc": "",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Make skin self-checks a Habit",
                    //               "summaryGoalOption": "Set a monthly reminder",
                    //               "summaryTitle": "Set a monthly reminder",
                    //               "summaryDesc": "Set phone reminders",
                    //               "summarySubDesc": "",
                    //               "summaryFutureOptions": [
                    //                 "<strong>Use phone reminders</strong> to remind yourself and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             },
                    //             {
                    //               "imageUrl": "assets/images/Calander.png",
                    //               "title": "Mark a date on your calendar ",
                    //               "desc": "",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Make skin self-checks a Habit",
                    //               "summaryGoalOption": "Set a monthly reminder",
                    //               "summaryTitle": "Set a monthly reminder",
                    //               "summaryDesc": "Mark a date on your calendar",
                    //               "summarySubDesc": "",
                    //               "summaryFutureOptions": [
                    //                 "<strong>Write the date in your calendar</strong> to remind yourself and keep track of your progress in  <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             }
                    //           ]
                    //         }
                    //       },
                    //       {
                    //         "imageUrl": "assets/icons/UserFilled.png",
                    //         "text": "Find someone to hold me accountable ",
                    //         "options": {
                    //           "mainImgUrl": "assets/images/help.png",
                    //           "mainTitle": "Make Skin Self-Checks a Habit",
                    //           "mainDesc": "Find someone to hold me accountable",
                    //           "mainDesc2": "<strong>Select which option</strong> you will use to find someone to hold you accountable for completing a skin self-check each month.",
                    //           "showBottomCheckBox": false,
                    //           "options": [
                    //             {
                    //               "imageUrl": "assets/images/Family.png",
                    //               "title": "Ask a <strong>family member</strong>",
                    //               "desc": "",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Make skin self-checks a Habit",
                    //               "summaryGoalOption": "finding someone to help",
                    //               "summaryTitle": "Find Someone to Help",
                    //               "summaryDesc": "Ask a family member",
                    //               "summarySubDesc": "",
                    //               "summaryFutureOptions": [
                    //                 "<strong>Ask a family to help</strong> you in skin self-check and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             },
                    //             {
                    //               "imageUrl": "assets/images/Friends.png",
                    //               "title": "Ask a friend",
                    //               "desc": "",
                    //               "isSelected": false,
                    //               "isThere": false,
                    //               "summaryGoal": "Make skin self-checks a Habit",
                    //               "summaryGoalOption": "finding someone to help",
                    //               "summaryTitle": "Find Someone to Help",
                    //               "summaryDesc": "Ask a <strong>friend</strong>",
                    //               "summarySubDesc": "",
                    //               "summaryFutureOptions": [
                    //                 "<strong>Ask a friend to help</strong> you in skin self-check and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                    //                 "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                    //                 "Remind me to complete my skin self-check at a later date."
                    //               ]
                    //             }
                    //           ]
                    //         }
                    //       }
                    //     ]
                    //   }
                    // ]}
                    options={content.activityOptions}
                    click={(options: any) => {
                      console.log("task selected i: " + options);
                      DataSubmissionService.chooseAGoalOption = options;

                      //Go to next screen
                      currentScreenIndex += 1;
                      resetHiddenContents();
                      setCurrentCoreScreen(
                        currentCoreScreens[currentScreenIndex]
                      );
                      let currScreen = currentCoreScreens[currentScreenIndex];
                      if (
                        "footer-buttons" in currScreen &&
                        currScreen.footerButtons.length > 0
                      ) {
                        setEnableNextBtn(
                          !currScreen.footerButtons[0].isDisabled
                        );
                        console.log(enableNextBtn);
                      }
                    }}
                    mainOptionClick={(index: number) => {
                      if (index >= 0) {
                        if (DataSubmissionService.mainGoalIndex !== index) {
                          DataSubmissionService.c1A1SkinSelfCheckBarriers = [];
                        }

                        let indexes =
                          content.activityOptions[index].barrierIndexes;
                        DataSubmissionService.c1A1BarrierIndexes = [
                          0, 1, 2, 3, 4, 5,
                        ]; //indexes;

                        DataSubmissionService.mainGoalIndex = index;
                      }
                    }}
                  />
                </div>
              );
            }
            // ________________________________________________________________
            /* Contents Img Text Col Tappable*/
            if (content.type === "img-text-col-tappable") {
              return (
                <div key={i}>
                  <ImgTextColTappable
                    text={content.text}
                    imageUrl={content.imageUrl}
                    click={() => {
                      if (content.text.includes("Skip Barrier")) {
                        screensSkipped = 3;
                        skippedScreenIndex = currentScreenIndex + 3;
                        currentScreenIndex += 3;
                      } else {
                        currentScreenIndex += 1;
                      }
                      resetHiddenContents();
                      setCurrentCoreScreen(
                        currentCoreScreens[currentScreenIndex]
                      );
                      let currScreen = currentCoreScreens[currentScreenIndex];
                      if (currScreen.footerButtons.length > 0) {
                        setEnableNextBtn(
                          !currScreen.footerButtons[0].isDisabled
                        );
                        console.log(enableNextBtn);
                      }
                    }}
                  />
                </div>
              );
            }
            // ________________________________________________________________
            /* Contents MCQ Multiple Check */
            if (content.type === "barrier-mcq-multiple-check") {
              return (
                <div key={i}>
                  <BarrierMCQMultipleCheck
                    question={content.question}
                    desc={content.desc}
                    // options={getBarrierOptions([
                    //   {
                    //     "text": "<strong>I don’t feel confident</strong> performing a skin self-check.",
                    //     "solution": {
                    //       "isViewed": false,
                    //       "isChecked": true,
                    //       "text": "Confidence",
                    //       "desc": "It is <strong>completely normal</strong> to feel this way. You will become more and <strong>more confident</strong> as you progress through your skin self-check.",
                    //       "pointsDesc":
                    //         "Which of the following <strong>strategies</strong> will you use?",
                    //       "points": [
                    //         "Review <span class='text-underline'>mySmartSkin content</span> to increase knowledge and skills",
                    //         "<strong>Print out pictures</strong> of abnormal spots to compare my spots",
                    //         "<strong>Have someone</strong> to help me during my skin self-check"
                    //       ],
                    //       "textSummary": "Confident",
                    //       "pointsDescSummary": "To feel confident, I will",
                    //       "pointsSummary": [
                    //         "Review MSS to increase knowledge and skill set",
                    //         "Print out pictures of abnormal spots to compare my spots",
                    //         "Have someone to help me during my skin self-check"
                    //       ]
                    //     }
                    //   },
                    //   {
                    //     "text": "I am <strong>anxious</strong> to find an abnormal spot.",
                    //     "solution": {
                    //       "isViewed": false,
                    //       "isChecked": true,
                    //       "text": "Anxiety",
                    //       "desc": "It is <strong>completely normal</strong> to be <strong>anxious</strong> about finding abnormal spots.",
                    //       "pointsDesc":
                    //         "Which of the following <strong>strategies</strong> will you use?",
                    //       "points": [
                    //         "<strong>Have someone</strong> to help me during my skin self-check",
                    //         "<strong>Schedule an appointment</strong> with <strong>my doctor</strong> to review any suspicious spots I find during my skin self-check"
                    //       ],
                    //       "textSummary": "Anxious",
                    //       "pointsDescSummary": "To feel less anxious, I will",
                    //       "pointsSummary": [
                    //         "Ask for help with my skin self-check.",
                    //         "Schedule an appointment with my doctor to review the suspicious spots"
                    //       ]
                    //     }
                    //   },
                    //   {
                    //     "text": "I <strong>don't have time</strong> to do a skin self-check.",
                    //     "solution": {
                    //       "isViewed": false,
                    //       "isChecked": true,
                    //       "text": "Don't Have Time",
                    //       "desc": "The most thorough skin self-checks only take <strong>10-15 minutes to complete</strong> each month.<br><br>Although it may be an inconvenience, doing these skin self-checks is an important part of your survivorship because they <strong>help you to monitor</strong> spots on your body for abnormalities.",
                    //       "pointsDesc":
                    //         "",
                    //       "points": [

                    //       ],
                    //       "textSummary": "Don't Have Time",
                    //       "pointsDescSummary": "It only takes 10-15 minutes to complete skin self-check ",
                    //       "pointsSummary": [

                    //       ]
                    //     }
                    //   },
                    //   {
                    //     "text": "I <strong>need help</strong> doing a skin self-check because I have too many spots.",
                    //     "solution": {
                    //       "isViewed": false,
                    //       "isChecked": true,
                    //       "text": "Need Help",
                    //       "desc": "Although you want to check all body parts, you only need to note and <strong>monitor abnormal spots</strong> on your body map.",
                    //       "pointsDesc":
                    //         "Which of the following <strong>strategies</strong> will you use?",
                    //       "points": [
                    //         "<strong>Complete one body part</strong> at a time to make it manageable",
                    //         "<strong>Focus on the spots</strong> that I am most concerned about",
                    //         "<strong>Ask a family</strong> member or friend to help me."
                    //       ],
                    //       "textSummary": "Need Help",
                    //       "pointsDescSummary": "To feel less overwhelmed, I Will",
                    //       "pointsSummary": [
                    //         "Complete one body part at a time to make it manageable",
                    //         "Focus on the spots that I am most concerned about",
                    //         "Ask a family member or friend to help me."
                    //       ]
                    //     }
                    //   },
                    //   {
                    //     "text": "I would <strong>prefer a doctor</strong> examine my skin.",
                    //     "solution": {
                    //       "isViewed": false,
                    //       "isChecked": true,
                    //       "text": "Prefer a Doctor",
                    //       "desc": "Doing skin self-checks are not instead of having your doctor check your body for abnormal spots. Self-checks allow you to <strong>monitor your body</strong> in between doctor appointments to make sure there is <strong>nothing suspicious</strong>.<br><br>skin self-checks are especially important because about <strong>50% of people find their skin cancer</strong> on their own. If you do find an abnormal or changing spot, you should schedule an appointment with your physician.</br></br>",
                    //       "pointsDesc":
                    //         "",
                    //       "points": [

                    //       ],
                    //       "textSummary": "Prefer a Doctor",
                    //       "pointsDescSummary": "skin self-checks are especially important because about 50% of people find their skin cancer on their own. If you do find an abnormal or changing spot, you should schedule an appointment with your physician.",
                    //       "pointsSummary": [

                    //       ]
                    //     }
                    //   },
                    //   {
                    //     "text": "<strong>None of these</strong>",
                    //     "solution": {
                    //       "isViewed": false,
                    //       "isChecked": true,
                    //       "text": "None of these",
                    //       "desc": "It is <strong>completely normal</strong> to feel none. You will become more and <strong>more confident</strong> as you progress through your skin self-check.",
                    //       "pointsDesc":
                    //         "Following <strong>strategies</strong> will help you to overcome your barrier.",
                    //       "points": [
                    //         "None <strong>Review MSS</strong> to increase knowledge and skill set",
                    //         "<strong>Print out pictures</strong> of abnormal spots to compare my spots",
                    //         "<strong>Have someone</strong> to help me during my skin self-check"
                    //       ],
                    //       "textSummary": "None of these"
                    //     }
                    //   },
                    //   {
                    //     "isChecked": false,
                    //     "text": "Need Help",
                    //     "desc": "It is <strong>completely normal</strong> to feel this way. You will become more and <strong>more confident</strong> as you progress through your skin self-check.",
                    //     "pointsDesc":
                    //       "Following <strong>strategies</strong> will help you to overcome your barrier.",
                    //     "points": [
                    //       "<strong>Review MSS</strong> to increase knowledge and skill set",
                    //       "<strong>Print out pictures</strong> of abnormal spots to compare my spots",
                    //       "<strong>Have someone</strong> to help me during my skin self-check"
                    //     ]
                    //   }
                    // ])}
                    options={content.activityOptions}
                    noChangesCallback={() => {
                      //Temp Solution
                      // DataSubmissionService.c1A1CameBackFromBarrierSolutions =
                      //   false;
                      setEnableNextBtn(true);
                    }}
                    selected={(selectedOptions: Array<string>) => {
                      DataSubmissionService.noneOfTheseSelected = false;
                      setEnableNextBtn(false);
                      if (selectedOptions.length > 0) {
                        setEnableNextBtn(true);
                      }
                      DataSubmissionService.c1A1SkinSelfCheckBarriers =
                        selectedOptions;

                      if (
                        selectedOptions.length > 0 &&
                        (selectedOptions[0].toString() === "true" ||
                          selectedOptions[0].toString() === "false")
                      ) {
                        let solutions: any[] = [];

                        if (selectedOptions[selectedOptions.length - 1]) {
                          DataSubmissionService.noneOfTheseSelected = true;
                          selectedOptions.map((option: any, i: number) => {
                            if (i < selectedOptions.length - 1)
                              solutions.push(
                                DataSubmissionService
                                  .c1A1SkinSelfCheckBarriersSolutionsAllOptions[
                                  DataSubmissionService.c1A1BarrierIndexes[i]
                                ].solution
                              );
                          });
                          // solutions.push(
                          //   content.options[content.options.length - 1]
                          // );
                        } else {
                          if (selectedOptions[selectedOptions.length - 1]) {
                            DataSubmissionService.noneOfTheseSelected = true;
                            selectedOptions.map((option: any, i: number) => {
                              if (option) {
                                if (i < selectedOptions.length - 1) {
                                  solutions.push(
                                    DataSubmissionService
                                      .c1A1SkinSelfCheckBarriersSolutionsAllOptions[
                                      DataSubmissionService.c1A1BarrierIndexes[
                                      i
                                      ]
                                    ].solution
                                  );
                                }
                              }
                            });
                            // solutions.push(
                            //   content.options[content.options.length - 1]
                            // );
                          } else {
                            if (selectedOptions[selectedOptions.length - 1]) {
                              DataSubmissionService.noneOfTheseSelected = true;
                              selectedOptions.map((option: any, i: number) => {
                                if (i < selectedOptions.length - 1) {
                                  solutions.push(
                                    DataSubmissionService
                                      .c1A1SkinSelfCheckBarriersSolutionsAllOptions[
                                      DataSubmissionService.c1A1BarrierIndexes[
                                      i
                                      ]
                                    ].solution
                                  );
                                }
                              });
                              // solutions.push(
                              //   content.options[content.options.length - 1]
                              // );
                            } else {
                              selectedOptions.map((option: any, i: number) => {
                                if (option) {
                                  let allSols =
                                    DataSubmissionService.c1A1SkinSelfCheckBarriersSolutionsAllOptions;
                                  let barIndexes =
                                    DataSubmissionService.c1A1BarrierIndexes;
                                  let ind = i;
                                  let barInd =
                                    DataSubmissionService.c1A1BarrierIndexes[
                                    ind
                                    ];
                                  let sol = allSols[barInd];

                                  if (sol !== undefined) {
                                    solutions.push(
                                      DataSubmissionService
                                        .c1A1SkinSelfCheckBarriersSolutionsAllOptions[
                                        DataSubmissionService
                                          .c1A1BarrierIndexes[i]
                                      ].solution
                                    );
                                  }
                                }
                              });
                              // solutions.push(
                              //   content.options[content.options.length - 1]
                              // );
                            }
                          }
                        }
                        DataSubmissionService.c1A1SkinSelfCheckBarriersSolutions =
                          solutions;
                      } else {
                        let solutions: any[] = [];

                        if (
                          (
                            (selectedOptions[selectedOptions.length - 1] as any)
                              .text as string
                          ).includes("None of these")
                        ) {
                          DataSubmissionService.noneOfTheseSelected = true;
                          for (
                            let index = 0;
                            index <
                            DataSubmissionService.c1A1BarrierIndexes.length - 1;
                            index++
                          ) {
                            solutions.push(
                              DataSubmissionService
                                .c1A1SkinSelfCheckBarriersSolutionsAllOptions[
                                DataSubmissionService.c1A1BarrierIndexes[index]
                              ].solution
                            );
                          }
                          // solutions.push(
                          //   content.options[content.options.length - 1]
                          // );
                        } else {
                          selectedOptions.map((option: any, i: number) => {
                            solutions.push(option.solution);
                          });
                          // solutions.push(
                          //   content.options[content.options.length - 1]
                          // );
                        }

                        DataSubmissionService.c1A1SkinSelfCheckBarriersSolutions =
                          solutions;
                      }
                    }}
                  />
                </div>
              );
            }
            // ________________________________________________________________
            /* Contents Accordian Barrier Solution */
            if (content.type === "accordian-barrier-solution") {
              return getBarrierSolutionsUI();
              // return (
              //   <>
              //     {DataSubmissionService.c1A1SkinSelfCheckBarriersSolutions.map(
              //       (option: any, i: number) => {
              //         if (option !== undefined) {
              //           return (
              //             <AccordianBarrierSolution
              //               solIsViewedCallback={(viewed: boolean) => {
              //                 let sols =
              //                   DataSubmissionService
              //                     .c1A1SkinSelfCheckBarriersSolutions[i];
              //                 console.log(sols);
              //                 if (viewed) {
              //                   (sols as any).isViewed = viewed;
              //                   setRefreshContents(!refreshContents);
              //                 }

              //                 let solutions =
              //                   DataSubmissionService.c1A1SkinSelfCheckBarriersSolutions;
              //                 setEnableNextBtn(true);
              //                 for (
              //                   let index = 0;
              //                   index < solutions.length;
              //                   index++
              //                 ) {
              //                   if (!(solutions[index] as any).isViewed) {
              //                     setEnableNextBtn(false);
              //                   }
              //                 }
              //               }}
              //               isViewed={option.isViewed}
              //               isChecked={option.isChecked}
              //               text={option.text}
              //               desc={option.desc}
              //               pointsDesc={option.pointsDesc}
              //               points={option.points}
              //             />
              //           );
              //         }
              //       }
              //     )}
              //   </>
              // );
            }
            // ________________________________________________________________
            /* Contents Gap */
            if (content.type === "gap") {
              return (
                <>
                  <div style={{ width: "100%", height: `${content.height}` }} />
                </>
              );
            }
            // ________________________________________________________________
            /* Contents Acc Tools Selected */
            if (content.type === "accordion-tools-selected") {
              return getAccordianGoalSelectedUI(content);
            }
            // ________________________________________________________________
            /* Contents Acc Barrier Solution Summary */
            if (content.type === "barrier-solution-summary") {
              return (
                <>
                  <AccordianBarrierSolutionSummary
                    title={content.title}
                    options={DataSubmissionService.c1A1SkinSelfCheckBarriers}
                  />
                </>
              );
            }
            // ________________________________________________________________
            /* Contents Acc Barrier Solution Summary */
            if (content.type === "accordion-future-summary") {
              return getInTheFutureUI(content);
            }
            // ________________________________________________________________
            /* Contents Acc Completed Chapters Summary */
            if (content.type === "completed-chapters-summary") {
              return (
                <>
                  <CompletedChaptersSummary
                    title={content.title}
                    options={content.multipleOptions}
                  />
                </>
              );
            }
            // ________________________________________________________________
            /* Contents Acc Resources Summary */
            if (content.type === "accordion-resources-summary") {
              return (
                <>
                  <AccordianResourcesSummary
                    text={content.title}
                    desc={content.desc}
                    options={content.options}
                  />
                </>
              );
            }
            // ________________________________________________________________
            /* Contents Core FnF */
            if (content.type === "fnf") {
              return (
                <>
                  <CoreFnf
                    data={content.data}
                    isSelected={(answerData: Array<any>) => {
                      console.log(answerData);
                      QuizService.captureFnF(answerData, currentScreenIndex);

                      setEnableNextBtn(true);
                    }}
                    isIndividualSelected={(answerData: any) => {
                      console.log(answerData);

                      const obj = {
                        type: [
                          {
                            target_id: "quiz",
                          },
                        ],
                        title: [
                          {
                            value: answerData.title,
                          },
                        ],
                        field_question: [
                          {
                            value: answerData.question,
                          },
                        ],
                        field_answer: [
                          {
                            value: answerData.enteredAnswer.toLowerCase(),
                          },
                        ],
                        field_correct_answer: [
                          {
                            value: answerData.answerType.toLowerCase(),
                          },
                        ],
                      };

                      //Send to BE
                      setShowLoader(true);

                      postQuizFnf(obj, (success: boolean) => {
                        //Close loading screen.
                        setShowLoader(false);
                      });
                    }}
                  />
                </>
              );
            }
            // ________________________________________________________________
            /* Contents Core FnF */
            if (content.type === "fnf2") {
              return (
                <>
                  <CoreFnf2
                    data={content.data}
                    isSelected={(answerData: Array<any>) => {
                      QuizService.captureFnF(answerData, currentScreenIndex);

                      setEnableNextBtn(true);
                    }}
                  />
                </>
              );
            }
            // ________________________________________________________________
            /* Contents Core FnFMCQ */
            if (content.type === "fnfmcq") {
              return (
                <>
                  <CoreMCQ
                    data={content.data}
                    isSelected={(answerData: Array<any>) => {
                      QuizService.captureFnFMCQ(answerData, currentScreenIndex);

                      setEnableNextBtn(true);
                    }}
                  />
                </>
              );
            }
            // ________________________________________________________________
            /* Contents Core mcq2 */
            // if (content.type === "mcq2") {
            //   return (
            //     <CoreSingleCheckOption
            //       title={content.title}
            //       listOptions={content.listOptions}
            //       selected={(title: string, options: Array<any>) => {
            //         console.log(title);
            //       }}
            //     />
            //   );
            // }
            // ________________________________________________________________
            /* Contents Core mcq-increment-decrement */
            // if (content.type === "mcq-inc-dec") {
            //   return (
            //     <CoreIncrementDecrement
            //       question={content.question}
            //       onSelected={(count: number) => {
            //         console.log(count);
            //       }}
            //     />
            //   );
            // }
            // ________________________________________________________________
            /* Contents Core content-accordian */
            if (content.type === "content-accordian") {
              return (
                <CoreAccordionDescriptionIntro
                  desc={content.description}
                  accImg={content.accImg}
                  accHeader={content.accHeader}
                  accDesc={content.accDesc}
                  accPoints={content.accPoints}
                  bottomDesc={content.bottomDesc}
                />
              );
            }
            // ________________________________________________________________
            /* Contents Core content-accordian */
            if (content.type === "content-img-accordian") {
              return (
                <CoreAccordianImageDesc
                  setOpen={content.setOpen}
                  desc={content.description}
                  accDescHead={content.accDescHead}
                  accDescHead2={content.accDescHead2}
                  accDescPoints={content.accDescPoints}
                  accDescPoints2={content.accDescPoints2}
                />
              );
            }
            // ________________________________________________________________
            /* Contents Core vieo-player */
            if (content.type === "video-player") {
              return (
                <CoreVideoPlayer
                  coreVideoUrl={content.coreVideoUrl}
                  coreVideoText={content.coreVideoText}
                />
              );
            }
            // ________________________________________________________________
            /* Contents Core form */
            // if (content.type === "form-init") {
            //   return (
            //     <CoreFormInit
            //       question={content.question}
            //       desc={content.description}
            //       listOptions={content.listOptions}
            //       limitOptions={content.limitOptions}
            //       isGoal={content.isGoal}
            //       selected={(
            //         selectedOptions: Array<string>,
            //         selectedStrategies: Array<string>
            //       ) => {
            //         console.log(selectedOptions);
            //         console.log(selectedStrategies);

            //         formHandler(
            //           content.description,
            //           selectedOptions,
            //           selectedStrategies
            //         );
            //       }}
            //     ></CoreFormInit>
            //   );
            // }
            // ________________________________________________________________
            /* Contents Core form none. Currently only used for core 2 ch 3 Your tanning Beliefs */
            if (content.type === "form-none") {
              return (
                <CoreFormNone
                  question={content.question}
                  desc={content.description}
                  listOptions={content.listOptions}
                  limitOptions={content.limitOptions}
                  selected={(selectedOptions: Array<string>) => {
                    console.log(selectedOptions);
                    C2DataSubmissionService.tanningBeliefsSolutions = [];
                    const trueIndexes = selectedOptions
                      .map((value, index) => (value ? index : -1))
                      .filter((index) => index !== -1);
                    trueIndexes.forEach((indVal) => {
                      let obj = {
                        question: content.listOptions[indVal],
                        answer: content.points[indVal],
                      };
                      C2DataSubmissionService.tanningBeliefsSolutions.push(obj);
                    });
                    let sel = C2DataSubmissionService.tanningBeliefsSolutions;
                    console.log(sel);

                    if (sel.length > 0) {
                      const trueIndexes: number[] = [];
                      for (
                        let index = 0;
                        index < selectedOptions.length;
                        index++
                      ) {
                        const element = selectedOptions[index];
                        if (element.toString() === "true") {
                          trueIndexes.push(index);
                        }
                      }
                      const trueOptions = trueIndexes.map(
                        (index) => content.listOptions[index]
                      );

                      let currFormQstn = currentCoreScreen.contents[i - 1].text;
                      QuizService.captureMultipleCheck(
                        currentScreenIndex,
                        currFormQstn,
                        trueOptions
                      );

                      setEnableNextBtn(true);
                    } else {
                      setEnableNextBtn(false);
                    }
                  }}
                ></CoreFormNone>
              );
            }
            // ________________________________________________________________
            /* Contents Core form */
            if (content.type === "form") {
              return (
                <CoreForm
                  question={content.question}
                  desc={content.description}
                  listOptions={content.listOptions}
                  limitOptions={content.limitOptions}
                  selected={(
                    selectedOptions: Array<string>,
                    selectedStrategies: Array<string>
                  ) => {
                    console.log(selectedOptions);
                    console.log(selectedStrategies);
                    //Core-2 Chapter 4 Screen 3 List Options
                    if (
                      DataService.currentCoreIndex === 1 &&
                      DataService.currentChapterIndex === 3 &&
                      currentScreenIndex === 2
                    ) {
                      let currFormQstn = currentCoreScreen.contents[i - 1].text;
                      QuizService.captureMultipleCheck(
                        currentScreenIndex,
                        currFormQstn,
                        selectedOptions
                      );

                      C2DataSubmissionService.c2Ch4S3SunSafePriorityOpts =
                        selectedOptions;
                      C2DataSubmissionService.c2Ch4S3SunSafePriorityStrgs =
                        selectedStrategies;
                      setEnableNextBtn(true);
                      return;
                    }
                    formHandler(
                      content.description,
                      selectedOptions,
                      selectedStrategies
                    );
                  }}
                ></CoreForm>
              );
            }
            // ________________________________________________________________
            /* Contents Core form 1 */
            if (content.type === "form1") {
              return (
                <CoreForm1
                  question={content.question}
                  desc={content.description}
                  listOptions={content.listOptions}
                  limitOptions={content.limitOptions}
                  selected={(
                    selectedOptions: Array<string>,
                    selectedStrategies: Array<string>
                  ) => {
                    console.log(selectedOptions);
                    console.log(selectedStrategies);

                    formHandler(
                      content.description,
                      selectedOptions,
                      selectedStrategies
                    );
                  }}
                ></CoreForm1>
              );
            }
            // ________________________________________________________________
            /* Contents Core form 2 */
            if (content.type === "form2") {
              return (
                <CoreForm2
                  question={content.question}
                  desc={content.description}
                  listOptions={content.listOptions}
                  limitOptions={content.limitOptions}
                  selected={(
                    selectedOptions: Array<string>,
                    selectedStrategies: Array<string>
                  ) => {
                    console.log(selectedOptions);
                    console.log(selectedStrategies);

                    formHandler(
                      content.description,
                      selectedOptions,
                      selectedStrategies
                    );
                  }}
                ></CoreForm2>
              );
            }
            // ________________________________________________________________
            /* Contents Core form 3 */
            if (content.type === "form3") {
              return (
                <CoreForm3
                  question={content.question}
                  desc={content.description}
                  listOptions={content.listOptions}
                  limitOptions={content.limitOptions}
                  selected={(
                    selectedOptions: Array<string>,
                    selectedStrategies: Array<string>
                  ) => {
                    console.log(selectedOptions);
                    console.log(selectedStrategies);

                    formHandler(
                      content.description,
                      selectedOptions,
                      selectedStrategies
                    );
                  }}
                ></CoreForm3>
              );
            }
            // ________________________________________________________________
            /* Contents Core form 4 */
            if (content.type === "form4") {
              return (
                <CoreForm4
                  question={content.question}
                  desc={content.description}
                  listOptions={content.listOptions}
                  limitOptions={content.limitOptions}
                  selected={(
                    selectedOptions: Array<string>,
                    selectedStrategies: Array<string>
                  ) => {
                    console.log(selectedOptions);
                    console.log(selectedStrategies);

                    formHandler(
                      content.description,
                      selectedOptions,
                      selectedStrategies
                    );
                  }}
                ></CoreForm4>
              );
            }
            // ________________________________________________________________
            /* Contents Core form 5 */
            if (content.type === "form5") {
              return (
                <CoreForm5
                  question={content.question}
                  desc={content.description}
                  listOptions={content.listOptions}
                  limitOptions={content.limitOptions}
                  selected={(
                    selectedOptions: Array<string>,
                    selectedStrategies: Array<string>
                  ) => {
                    console.log(selectedOptions);
                    console.log(selectedStrategies);

                    formHandler(
                      content.description,
                      selectedOptions,
                      selectedStrategies
                    );
                  }}
                ></CoreForm5>
              );
            }
            // ________________________________________________________________
            /* Contents Core form 6 */
            if (content.type === "form6") {
              return (
                <CoreForm6
                  question={content.question}
                  desc={content.description}
                  listOptions={content.listOptions}
                  limitOptions={content.limitOptions}
                  selected={(
                    selectedOptions: Array<string>,
                    selectedStrategies: Array<string>
                  ) => {
                    console.log(selectedOptions);
                    console.log(selectedStrategies);

                    formHandler(
                      content.description,
                      selectedOptions,
                      selectedStrategies
                    );
                  }}
                ></CoreForm6>
              );
            }
            // ________________________________________________________________
            /* Contents Core form 3 */
            if (content.type === "form7") {
              return (
                <CoreForm7
                  question={content.question}
                  desc={content.description}
                  listOptions={content.listOptions}
                  limitOptions={content.limitOptions}
                  selected={(
                    selectedOptions: Array<string>,
                    selectedStrategies: Array<string>
                  ) => {
                    console.log(selectedOptions);
                    console.log(selectedStrategies);

                    formHandler(
                      content.description,
                      selectedOptions,
                      selectedStrategies
                    );
                  }}
                ></CoreForm7>
              );
            } /* Contents Core form 8*/
            if (content.type === "form8") {
              return (
                <CoreForm8
                  question={content.question}
                  desc={content.description}
                  listOptions={content.listOptions}
                  limitOptions={content.limitOptions}
                  selected={(
                    selectedOptions: Array<string>,
                    selectedStrategies: Array<string>
                  ) => {
                    console.log(selectedOptions);
                    console.log(selectedStrategies);
                    //Core-2 Chapter 4 Screen 4 List Options
                    if (
                      DataService.currentCoreIndex === 1 &&
                      DataService.currentChapterIndex === 3 &&
                      currentScreenIndex === 3
                    ) {
                      let currFormQstn = currentCoreScreen.contents[i - 1].text;
                      QuizService.captureMultipleCheck(
                        currentScreenIndex,
                        currFormQstn,
                        selectedOptions
                      );

                      C2DataSubmissionService.c2Ch4S4SunSafeImpOpts =
                        selectedOptions;
                      C2DataSubmissionService.c2Ch4S4SunSafeImpStrgs =
                        selectedStrategies;
                      setEnableNextBtn(true);
                      return;
                    }
                    formHandler(
                      content.description,
                      selectedOptions,
                      selectedStrategies
                    );
                  }}
                ></CoreForm8>
              );
            }

            // ________________________________________________________________
            /* Contents Core img text */
            if (content.type === "img-text") {
              return (
                <AccordianCoreContentImgExp
                  imageUrl={content.imageUrl}
                  text={content.text}
                />
              );
            }
            // ________________________________________________________________
            /* Contents Core img text */
            if (content.type === "task-selection-option") {
              return (
                <TaskSelectionOptionCore2
                  key={Math.random()}
                  index={0}
                  isOpen={true}
                  isSelected={() => { }}
                  imageUrl={""}
                  title={""}
                  subtitle={""}
                  desc={content.description}
                  options={content.activityOptions}
                  click={(opt: any) => {
                    /////////////////Check if 2 goals already selected//////////////////////
                    // setShowPopover({ open: true, event: undefined });
                    // return;
                    let tempGoalTitles = c2A1getSelectedGoals();
                    let tempGoalTitleMatched = false;
                    tempGoalTitles.forEach((gl: string) => {
                      if (opt.text === gl) {
                        tempGoalTitleMatched = true;
                      }
                    });

                    if (!tempGoalTitleMatched && tempGoalTitles.length > 1) {
                      setShowPopover({ open: true, event: undefined });
                      return;
                    }
                    /////////////////////////////////////////////////////////////////////////

                    c2A1GoalClick(opt.text, content.activityOptions);
                    screensSkipped = opt.screensSkip;
                    skippedScreenIndex = currentScreenIndex + screensSkipped;
                    currentScreenIndex += screensSkipped;
                    //Go to next screen
                    // currentScreenIndex += 1;
                    resetHiddenContents();
                    setCurrentCoreScreen(
                      currentCoreScreens[currentScreenIndex]
                    );
                    let currScreen = currentCoreScreens[currentScreenIndex];
                    if (currScreen.footerButtons.length > 0) {
                      setEnableNextBtn(!currScreen.footerButtons[0].isDisabled);
                      console.log(enableNextBtn);
                    }
                  }}
                />
              );
            }
            // ________________________________________________________________
            /* Contents Selected Points Summary*/
            if (content.type === "selected-points-summary-contents") {
              return (
                <div
                  key={i}
                  className="animate__animated animate__fadeInRight 2000ms animate__delay-1s "
                >
                  <SelectedPointsSummary
                    text={content.text}
                    points={getc2A1SummaryPoints()}
                    isActivitySummaryComp={false}
                  />
                </div>
              );
            }
            //Core 2 Likert
            // ________________________________________________________________
            /* Contents Core likert */

            if (content.type === "likertemoji") {
              console.log("likert question:" + content.text);
              return (
                <LikertScale
                  question={content.question}
                  likertClickIndex={(question: string, index: number) => {
                    console.log(question, index);
                    const likertIndex = index;

                    if (
                      DataService.currentCoreIndex === 1 &&
                      DataService.currentChapterIndex == 0 &&
                      currentScreenIndex === 3
                    ) {
                      if (
                        content.question ===
                        "Use sunscreen with SPF 30 or higher?"
                      ) {
                        C2DataSubmissionService.c2Ch1S3LikertQstn1 =
                          likertIndex;
                        localStorage.setItem(
                          "c2Ch1S3LikertQstn1",
                          likertIndex.toString()
                        );
                      } else if (
                        content.question === "Wear a long-sleeved shirt?"
                      ) {
                        C2DataSubmissionService.c2Ch1S3LikertQstn2 =
                          likertIndex;
                        localStorage.setItem(
                          "c2Ch1S3LikertQstn2",
                          likertIndex.toString()
                        );
                      } else if (
                        content.question === "Wear a wide-brimmed hat?"
                      ) {
                        C2DataSubmissionService.c2Ch1S3LikertQstn3 =
                          likertIndex;
                        localStorage.setItem(
                          "c2Ch1S3LikertQstn3",
                          likertIndex.toString()
                        );
                      } else if (content.question === "Wear sunglasses?") {
                        C2DataSubmissionService.c2Ch1S3LikertQstn4 =
                          likertIndex;
                        localStorage.setItem(
                          "c2Ch1S3LikertQstn4",
                          likertIndex.toString()
                        );
                      } else if (
                        content.question ===
                        "Avoid the sun from 10 a.m. to 4 p.m.?"
                      ) {
                        C2DataSubmissionService.c2Ch1S3LikertQstn5 =
                          likertIndex;
                        localStorage.setItem(
                          "c2Ch1S3LikertQstn5",
                          likertIndex.toString()
                        );
                      } else if (
                        content.question ===
                        "Stay in the shade or under an umbrella?"
                      ) {
                        C2DataSubmissionService.c2Ch1S3LikertQstn6 =
                          likertIndex;
                        localStorage.setItem(
                          "c2Ch1S3LikertQstn6",
                          likertIndex.toString()
                        );
                      } else if (content.question === "Have a sunburn?") {
                        C2DataSubmissionService.c2Ch1S3LikertQstn7 =
                          likertIndex;
                        localStorage.setItem(
                          "c2Ch1S3LikertQstn7",
                          likertIndex.toString()
                        );
                      } else if (
                        content.question ===
                        "Intentionally or incidentally tan?"
                      ) {
                        C2DataSubmissionService.c2Ch1S3LikertQstn8 =
                          likertIndex;
                        localStorage.setItem(
                          "c2Ch1S3LikertQstn8",
                          likertIndex.toString()
                        );
                      }
                    }

                    C2DataSubmissionService.c2Ch1TotalLikertScaleInd = 0;
                    if (C2DataSubmissionService.c2Ch1S3LikertQstn1 >= 0) {
                      C2DataSubmissionService.c2Ch1TotalLikertScaleInd +=
                        C2DataSubmissionService.c2Ch1S3LikertQstn1;
                    }
                    if (C2DataSubmissionService.c2Ch1S3LikertQstn2 >= 0) {
                      C2DataSubmissionService.c2Ch1TotalLikertScaleInd +=
                        C2DataSubmissionService.c2Ch1S3LikertQstn2;
                    }
                    if (C2DataSubmissionService.c2Ch1S3LikertQstn3 >= 0) {
                      C2DataSubmissionService.c2Ch1TotalLikertScaleInd +=
                        C2DataSubmissionService.c2Ch1S3LikertQstn3;
                    }
                    if (C2DataSubmissionService.c2Ch1S3LikertQstn4 >= 0) {
                      C2DataSubmissionService.c2Ch1TotalLikertScaleInd +=
                        C2DataSubmissionService.c2Ch1S3LikertQstn4;
                    }
                    if (C2DataSubmissionService.c2Ch1S3LikertQstn5 >= 0) {
                      C2DataSubmissionService.c2Ch1TotalLikertScaleInd +=
                        C2DataSubmissionService.c2Ch1S3LikertQstn5;
                    }
                    if (C2DataSubmissionService.c2Ch1S3LikertQstn6 >= 0) {
                      C2DataSubmissionService.c2Ch1TotalLikertScaleInd +=
                        C2DataSubmissionService.c2Ch1S3LikertQstn6;
                    }
                    if (C2DataSubmissionService.c2Ch1S3LikertQstn7 >= 0) {
                      C2DataSubmissionService.c2Ch1TotalLikertScaleInd +=
                        C2DataSubmissionService.c2Ch1S3LikertQstn7;
                    }
                    if (C2DataSubmissionService.c2Ch1S3LikertQstn8 >= 0) {
                      C2DataSubmissionService.c2Ch1TotalLikertScaleInd +=
                        C2DataSubmissionService.c2Ch1S3LikertQstn8;
                    }

                    if (
                      C2DataSubmissionService.c2Ch1S3LikertQstn1 >= 0 &&
                      C2DataSubmissionService.c2Ch1S3LikertQstn2 >= 0 &&
                      C2DataSubmissionService.c2Ch1S3LikertQstn3 >= 0 &&
                      C2DataSubmissionService.c2Ch1S3LikertQstn4 >= 0 &&
                      C2DataSubmissionService.c2Ch1S3LikertQstn5 >= 0 &&
                      C2DataSubmissionService.c2Ch1S3LikertQstn6 >= 0 &&
                      C2DataSubmissionService.c2Ch1S3LikertQstn7 >= 0 &&
                      C2DataSubmissionService.c2Ch1S3LikertQstn8 >= 0
                    ) {
                      QuizService.captureMultipleRadioBtns(currentScreenIndex);

                      unhideContent();
                      setEnableNextBtn(true);
                    }

                    setRefreshContents(!refreshContents);
                  }}
                />
              );
            }
            //Core 2 Single Check Option
            // ________________________________________________________________
            /* Contents Core Single Check Option */
            if (content.type === "singlecheckoption") {
              return (
                <CoreSingleCheckOption
                  title={content.question}
                  listOptions={content.listOptions}
                  selected={(title: string, options: Array<any>) => {
                    console.log(title);

                    let selI = -1;
                    let answer = "";
                    options.forEach((option: boolean, i: number) => {
                      if (option) {
                        selI = i;
                        answer = content.listOptions[selI];
                      }
                    });

                    if (currentScreenIndex === 7) {
                      if (answer.length > 0) {
                        QuizService.captureRadioMCQ(
                          currentScreenIndex,
                          content.question,
                          answer
                        );

                        if (title.includes("Have you ever sunbathed?")) {
                          C2DataSubmissionService.c2Ch3S1SinMCQ1 = answer;
                        } else if (
                          title.includes(
                            "Do you plan to sunbathe in the next 12 months?"
                          )
                        ) {
                          C2DataSubmissionService.c2Ch3S1SinMCQ2 = answer;
                        }

                        if (
                          C2DataSubmissionService.c2Ch3S1SinMCQ1.length > 0 &&
                          C2DataSubmissionService.c2Ch3S1IncDec >= 0 &&
                          C2DataSubmissionService.c2Ch3S1SinMCQ2.length > 0
                        ) {
                          setEnableNextBtn(true);
                        }
                      }
                    } else {
                      if (answer.length > 0) {
                        QuizService.captureRadioMCQ(
                          currentScreenIndex,
                          content.question,
                          answer
                        );

                        if (
                          title.includes(
                            "Have you ever used an indoor tanning bed or booth with tanning lamps?"
                          )
                        ) {
                          C2DataSubmissionService.c2Ch3S1SinTanMCQ1 = answer;
                        } else if (
                          title.includes(
                            "Do you think you will use an indoor tanning bed or booth in the next 12 months?"
                          )
                        ) {
                          C2DataSubmissionService.c2Ch3S1SinTanMCQ2 = answer;
                        }

                        if (
                          C2DataSubmissionService.c2Ch3S1SinTanMCQ1.length >
                          0 &&
                          C2DataSubmissionService.c2Ch3S1IncTanDec >= 0 &&
                          C2DataSubmissionService.c2Ch3S1SinTanMCQ2.length > 0
                        ) {
                          setEnableNextBtn(true);
                        }
                      }
                    }
                  }}
                />
              );
            }
            //Core 2 CoreIncrementDecrement
            // ________________________________________________________________
            /* Contents CoreIncrementDecrement */
            if (content.type === "incdec") {
              return (
                <CoreIncrementDecrement
                  question={content.question}
                  onSelected={(i: number) => {
                    if (currentScreenIndex === 7) {
                      QuizService.captureIncDec(
                        currentScreenIndex,
                        content.question,
                        i
                      );

                      C2DataSubmissionService.c2Ch3S1IncDec = i;
                      if (
                        C2DataSubmissionService.c2Ch3S1SinMCQ1.length > 0 &&
                        C2DataSubmissionService.c2Ch3S1IncDec >= 0 &&
                        C2DataSubmissionService.c2Ch3S1SinMCQ2.length > 0
                      ) {
                        setEnableNextBtn(true);
                      }
                    } else {
                      QuizService.captureIncDec(
                        currentScreenIndex,
                        content.question,
                        i
                      );

                      C2DataSubmissionService.c2Ch3S1IncTanDec = i;
                      if (
                        C2DataSubmissionService.c2Ch3S1SinTanMCQ1.length > 0 &&
                        C2DataSubmissionService.c2Ch3S1IncTanDec >= 0 &&
                        C2DataSubmissionService.c2Ch3S1SinTanMCQ2.length > 0
                      ) {
                        setEnableNextBtn(true);
                      }
                    }
                  }}
                />
              );
            }
            // ________________________________________________________________
            /* Contents CoreIncrementDecrement */
            if (content.type === "tanningbeliefs-answers") {
              return (
                <div key={Math.random()}>
                  {C2DataSubmissionService.tanningBeliefsSolutions.map(
                    (cont: any, i: any) => {
                      return (
                        <AccordianMainImgText
                          title={cont.question}
                          imageUrl="assets/icons/arrow-circle-right.png"
                          expMainImgText=""
                          expMainImgUrl=""
                          expansionPoints={[
                            {
                              text: cont.answer,
                              imageUrl:
                                "assets/icons/ExclamationCircleFill.svg",
                            },
                          ]}
                          open={i === accordianSelectedIndex}
                          isOpened={() => {
                            accordianSelectedIndex = i;
                            setRefreshContents(!refreshContents);
                          }}
                        />
                      );
                    }
                  )}
                </div>
              );
            }
            // ________________________________________________________________
            /* Contents Accordian-Activity-Person */
            if (content.type === "accordian-activity-person") {
              return (
                <div key={Math.random()}>
                  <AccordianActivityPerson
                    title={content.title}
                    description={content.description}
                    imageUrl={content.imageUrl}
                    mainimageUrl={content.mainImgUrl}
                    audioUrl={content.audioUrl}
                    text={content.text}
                    open={i === accordianSelectedIndex}
                    isOpened={() => {
                      openIndividualAccordian(content, i);
                    }}
                  ></AccordianActivityPerson>
                </div>
              );
            }
            // ________________________________________________________________
            /* Contents c2-goal-barriers-link */
            if (content.type === "c2-goal-barriers-link") {
              return (
                <div className="ccaDescTextContainer" key={i}>
                  <IonText className="ccaDescText">
                    If you want to add more barriers and strategies, return to
                    the{" "}
                    <span
                      className="text-underline"
                      onClick={c2A1NavToBarriers}
                    >
                      barriers screen
                    </span>{" "}
                    to select another option.
                  </IonText>
                </div>
              );
            }
            // ________________________________________________________________
            /* Contents c2-goal-goals-link
             */
            if (content.type === "c2-goal-goals-link") {
              return (
                <div className="ccaDescTextContainer" key={i}>
                  <IonText className="ccaDescText">
                    If you would like to set another goal, return to the{" "}
                    <span className="text-underline" onClick={c2A1NavToGoals}>
                      goals screen
                    </span>{" "}
                    to select another option. You can set up to 2 goals!
                  </IonText>
                </div>
              );
            }
            // ________________________________________________________________
            /* Contents image-popover */
            if (content.type === "image-popover") {
              return (
                <ImageDescriptionPopOver imageArray={content.activityOptions} />
              );
            }
            // ________________________________________________________________
            /* Contents image-text-accordians-dynamic*/
            if (content.type === "image-text-accordians-dynamic") {
              return getImgTextAccordianDynamicPoints();
            }
          })}
        </div>
        {/* set Reminders for first SSE for only after section 1 */}
        <IonModal
          ref={modal}
          isOpen={openReminder}
          onDidDismiss={() => {
            setOpenReminder(false);
          }}
          // trigger="open-modal"
          initialBreakpoint={1.0}
          breakpoints={[0, 0.75, 0.8, 1.0]}
        >
          <IonContent class="sscpsReminderContent">

            {SkinSelfCheckDataService.remScreen2Open && (

              <IonGrid>
                <div className="overlay-modal-content-main-div">
                  <IonImg
                    className="clock-img"
                    src="/assets/icons/ClockCircleOutline.png"
                  ></IonImg>
                  <IonText className="reminder-title">Set Reminder</IonText>
                </div>
                <hr className="horizontal-line"></hr>
                <div style={{ height: "100%" }}>
                  <IonRow>
                    <IonCol size="12" className="reminder-img">
                      <IonImg
                        src="/assets/images/set-reminder.png"
                        style={{ width: "160px" }}
                      ></IonImg>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="12"
                      style={{
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        display: "flex",
                      }}
                    >
                      <IonText
                        className="remind-title"
                        dangerouslySetInnerHTML={{ __html: reminderText }}
                      ></IonText>
                    </IonCol>
                  </IonRow>
                  <DateTimePicker
                    onselectedDatehandler={(date: string) => {
                      console.log("sel date from picker ", date);
                      dateTimePickerHandler(date);
                    } } noofDays="0" />
                </div>
              </IonGrid>

            )}
            <div>
              <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => handleTimeAlert}
                header={"Alert...!"}
                message={"You can't select past time"}
                buttons={[
                  {
                    text: "OK",
                    handler: handleTimeAlert,
                  },
                ]}
              />
            </div>
            {SkinSelfCheckDataService.remScreen3Open && (
              <>
                <IonGrid>
                  <IonRow className="rbpBadgePModalTitleRow">
                    <IonCol size="auto">
                      <IonImg src="assets/icons/ClockCircleOutline.png" />
                    </IonCol>
                    <IonCol
                      size="auto"
                      className="rbpBadgePModalTitleTextCol"
                    >
                      <IonText className="rbpPModalText">
                        Set Your Reminder
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <div className="rbpBadgePModalTitleDivider"></div>
                <IonGrid>
                  <IonRow className="sscpsopenSummaryRow">
                    <IonCol size="12" className="rbpBadgePModalBadgeImgCol">
                      <IonImg
                        className="sscpsopenSummaryImage"
                        src="/assets/images/set-reminder.png"
                      />
                    </IonCol>
                    <IonCol
                      size="12"
                      className="rbpBadgePModalBadgeImgCol rbpBadgePModalBadgeProgressCol"
                    ></IonCol>
                    <IonCol size="12" className="rbpBadgePModalBadgeImgCol">
                      <IonText className="rbpCompleteBadgeText">
                        <span>You have set a reminder on</span>
                        <span> </span>
                        {reminderDateTime}
                        <span> </span>
                        <span>to remind you to do your skin self-check.</span>
                      </IonText>
                    </IonCol>
                    <IonCol size="12" className="rbpBadgePModalBadgeImgCol">
                      <IonText className="sscpsopenSummaryTextlast">
                        Remember to reach out to friends or family to help
                        check your spots.
                      </IonText>
                    </IonCol>
                    <IonCol size="12" className="rbpBadgePModalBadgeImgCol">
                      <IonText className="sscpsopenSummaryTextlast">
                        See you then!
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </>
            )}
          </IonContent>
          {(SkinSelfCheckDataService.remScreen1Open ||
            SkinSelfCheckDataService.remScreen2Open) && (
              <div className="buttonFooter overlay-footer-btns">
                <IonGrid>
                  <IonRow>
                    <IonCol
                      className="ffsscpsButton12Col"
                      size="6"
                      style={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      }}
                    >
                      <IonButton
                        className="sscpsButton12 overlay-cancel-btn"
                        style={{
                          width: "100%",
                        }}
                        onClick={() => cancelReminderHandler()}
                      >
                        <IonImg src="assets/icons/CloseCircleOutline.png"></IonImg>
                        <IonText className="sscpsReminderButtonsText">
                          Cancel
                        </IonText>
                      </IonButton>
                    </IonCol>

                    <IonCol
                      className="ffsscpsButton12Col"
                      size="6"
                      style={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      }}
                    >
                      <IonButton
                        className="loginBtnEnabled ffsscpsButtonNext"
                        style={{
                          width: "100%",
                        }}
                        disabled={disableReminderBtn}
                        onClick={() => {
                         // setReminderHandler();
                         console.log("skincheckReminderDateTime",SkinSelfCheckDataService.skincheckReminderDateTime)
                         let date =new Date(SkinSelfCheckDataService.skincheckReminderDateTime).getTime();
                           postSSENotificationReminder(parseInt(format(date,"dd")));
                         
                        }}
                      >
                        <IonImg
                          src="assets/icons/CheckCircleOutline.png"
                          className="sscpsReminderButtonsImg"
                        ></IonImg>
                        <IonText className="sscpsReminderButtonsText">
                          Set Reminder
                        </IonText>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            )}
          {SkinSelfCheckDataService.remScreen3Open && (
            <div className="buttonFooter overlay-footer-btns">
              <IonGrid>
                <IonRow>
                  <IonCol
                    className="ffsscpsButton12Col"
                    size="12"
                    style={{
                      paddingLeft: "16px",
                      paddingRight: "16px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    }}
                  >
                    <IonButton
                      className="loginBtnEnabled ffsscpsButtonNext"
                      style={{
                        width: "100%",
                      }}
                      onClick={() => {
                      //   console.log("skincheckReminderDateTime",SkinSelfCheckDataService.skincheckReminderDateTime)
                      // let date =new Date(SkinSelfCheckDataService.skincheckReminderDateTime).getTime();
                      //   postSSENotificationReminder(parseInt(format(date,"dd")));
                        setOpenReminder(false);
                        SkinSelfCheckDataService.showFirstSSERemainderFlag = false;
                        DataService.currentCoreIndex += 1;
                        DataService.currentChapterIndex = 0;
                        let core2Ch1Screens = DataService.getScreens(
                          DataService.currentCoreIndex,
                          DataService.currentChapterIndex
                        );
                        console.log(core2Ch1Screens);
                        DataService.currentCoreScreens = core2Ch1Screens;
                        if (DataService.currentCoreScreens) {
                          resetHiddenContents();
                          setCurrentCoreScreen(DataService.currentCoreScreens[0]);
                          let currScreen = currentCoreScreens[currentScreenIndex];
                          if (currScreen.footerButtons.length > 0) {
                            setEnableNextBtn(!currScreen.footerButtons[0].isDisabled);
                            console.log(enableNextBtn);
                          }
                        }


                      }}
                    >
                      <IonImg
                        src="/assets/icons/CheckCircleOutline.png"
                        className="sscpsccoImg"
                      ></IonImg>
                      <IonText className="sscpsopensummarytextdone">
                        Done
                      </IonText>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          )}
        </IonModal>




      </IonContent>
      {currentCoreScreen.footerButtons !== null &&
        currentCoreScreen.footerButtons.length === 1 && (
          <div className="buttonFooter">
            <IonGrid>
              <IonRow>
                <IonCol
                  size="12"
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  {enableNextBtn && (
                    <IonButton
                      className="loginBtnEnabled"
                      style={{
                        width: "100%",
                      }}
                      onClick={() => {
                        accordianSelectedIndex = -1;
                        let savedOptions = null;
                        savedOptions =
                          DataSubmissionService.c1A1SkinSelfCheckReasons;

                        if (savedOptions && savedOptions.length > 0) {
                          if (
                            savedOptions[0].toString() === "true" ||
                            savedOptions[0].toString() === "false"
                          ) {
                            let realSavedOptions = [];
                            for (let i = 0; i < savedOptions.length; i++) {
                              if (savedOptions[i]) {
                                realSavedOptions.push(
                                  DataSubmissionService
                                    .c1A1SkinSelfCheckReasonsAllOptions[i]
                                );
                              }
                            }
                            DataSubmissionService.c1A1SkinSelfCheckReasons = [];
                            DataSubmissionService.c1A1SkinSelfCheckReasons =
                              realSavedOptions;
                          }
                        }

                        let barSols =
                          DataSubmissionService.c1A1SkinSelfCheckBarriersSolutions;

                        // router.push("/corechaptercontentone", "forward", "push");
                        if (
                          "skipScreens" in currentCoreScreen &&
                          currentCoreScreen.skipScreens !== null &&
                          currentCoreScreen.skipScreens !== undefined &&
                          currentCoreScreen.skipScreens > 0
                        ) {
                          if (!DataService.coreOpenedFromCoresTab) {
                            currentScreenIndex =
                              currentScreenIndexUpdateC2A1Goal;
                          }
                          screensSkipped = currentCoreScreen.skipScreens;
                          skippedScreenIndex =
                            currentScreenIndex + screensSkipped;
                          currentScreenIndex += screensSkipped;
                        } else {
                          currentScreenIndex += 1;
                        }

                        resetHiddenContents();
                        setCurrentCoreScreen(
                          currentCoreScreens[currentScreenIndex]
                        );
                        let currScreen = currentCoreScreens[currentScreenIndex];

                        if (
                          currScreen.contents.length > 0 &&
                          currScreen.contents.length > 1 &&
                          "text" in currScreen.contents[1] &&
                          currScreen.contents[1].text === "Barriers & Solutions"
                        ) {
                          checkEnableButtonForBarrierSolutions();
                        }

                        if (
                          currScreen.footerButtons !== null &&
                          currScreen.footerButtons.length > 0
                        ) {
                          setEnableNextBtn(
                            !currScreen.footerButtons[0].isDisabled
                          );
                          console.log(enableNextBtn);
                        }
                        // DataSubmissionService.getC1A1BEJson();

                        //Update progress
                        if (
                          currScreen.title.includes("Activity") ||
                          currScreen.title.includes("Goal")
                        ) {
                          //   let c2Options = null;
                          // c2Options = DataSubmissionService.c2A1SkinSelfCheckGoals;
                          // console.log(c2Options.goals);
                          //Core 1 activity temporary
                          let coreNo = DataService.currentCoreIndex;
                          let chNo = DataService.currentChapterIndex;
                          console.log(coreNo);

                          if (
                            currentScreenIndex === 6 ||
                            currentScreenIndex === 9
                          ) {
                            openLoader();
                            setLoaderText("Saving Chapter Progress")
                            setTimeout(() => {
                              ProgressDataService.updateProgressOfCurrChapter(
                                100,
                                true
                              );
                              postProgressData(
                                ProgressDataService.progressObj,
                                true
                              );
                            }, 1000);
                          }

                          return;
                        }
                        let lastScrIndex =
                          DataService.currentCoreScreens.length - 1;
                        if (currentScreenIndex === lastScrIndex) {
                          openLoader();
                          setLoaderText("Saving Chapter Progress")
                          setTimeout(() => {
                            console.log(
                              "Curr Sec >>> " + DataService.currentCoreIndex
                            );
                            console.log(
                              "Curr Chap >>> " + DataService.currentChapterIndex
                            );

                            if (

                              (DataService.currentCoreIndex === 1 &&
                                currentCoreScreen.title === "Chapter 5")
                            ) {
                              //Show Rewards Popover
                              setShowRewardsPopover({
                                open: true,
                                event: undefined,
                              });
                            }

                            //Todo
                            ProgressDataService.updateProgressOfCurrChapter(
                              100
                            );
                            postProgressData(ProgressDataService.progressObj);
                          }, 1000);
                        }
                      }}
                    >
                      {currentCoreScreen.footerButtons[0].text}
                    </IonButton>
                  )}
                  {!enableNextBtn && (
                    <IonButton
                      disabled={true}
                      className="loginBtnEnabled"
                      style={{
                        width: "100%",
                      }}
                      onClick={() => { }}
                    >
                      {currentCoreScreen.footerButtons[0].text}
                    </IonButton>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        )}
      {currentCoreScreen.footerButtons !== null &&
        currentCoreScreen.footerButtons.length === 2 && (
          <div className="buttonFooter">
            <IonGrid>
              <IonRow className="ion-justify-content-center">
                <IonCol
                  className="custom-flex"
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  <IonButton
                    className="loginBtnDefault"
                    onClick={() => {
                      if (
                        (
                          currentCoreScreen.footerButtons[0].text as string
                        ).includes("Sections")
                      ) {
                        navigateBackToCores();
                      } else {
                        navigateBackToChapter();
                      }
                    }}
                  >
                    <IonImg
                      className="custom-icon ios"
                      src={currentCoreScreen.footerButtons[0].image}
                    ></IonImg>{" "}
                    {currentCoreScreen.footerButtons[0].text}
                  </IonButton>
                  <IonButton
                    className="loginBtnEnabled"
                    onClick={() => {
                      if (
                        (
                          currentCoreScreen.footerButtons[1].text as string
                        ).includes("Lesson")
                      ) {
                        navigateToNextLesson();
                      } else if (
                        (
                          currentCoreScreen.footerButtons[1].text as string
                        ).includes("Core Summary")
                      ) {
                        //Go to next screen
                        currentScreenIndex += 1;
                        resetHiddenContents();
                        setCurrentCoreScreen(
                          currentCoreScreens[currentScreenIndex]
                        );
                      } else if (
                        (
                          currentCoreScreen.footerButtons[1].text as string
                        ).includes("Next Section")
                      ) {
                        //Go to next Core
                        navigateToNextCore();
                      } else if (
                        (
                          currentCoreScreen.footerButtons[1].text as string
                        ).includes("Exit")
                      ) {
                        //Back to Cores
                        navigateBackToCores();
                      } else {
                        navigateToNextChapter();
                      }
                    }}
                  >
                    <IonImg
                      className="custom-icon ios"
                      src={currentCoreScreen.footerButtons[1].image}
                    ></IonImg>{" "}
                    {currentCoreScreen.footerButtons[1].text}
                  </IonButton>{" "}
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        )}
    </IonPage>
  );
};

export default CoreChapterContents;
